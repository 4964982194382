import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import DepartmentServices from '../../../service/department';
import { useQuery } from '@tanstack/react-query';
import CustomPaper from '../../utilities/customPaper';
// import AssetService from '../../../service/asset';

const items = [
  {
    labels: 'Days',
    placeholder: 'enter a day',
    required: true,
    form: 'start_day',
    type: 'number',
  },
];

export const AssetWithOutPrestart = ({ assetFilters, callback }) => {
  const { handleSubmit, register } = useForm();
  const [depart, setDepart] = React.useState(null);

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_asset'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      return resp;
    },
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log(depart);
    const { start_day } = data;
    console.log(start_day);

    assetFilters({
      departId: depart?.id,
      start_day: start_day,
    });
    callback(true);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={5} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Department</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            PaperComponent={CustomPaper}
            options={!isDepartment ? departments : []}
            value={depart}
            loading={isDepartment}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setDepart(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size='small' required />
            )}
          />
        </Grid>

        {items.map((item) => (
          <Grid item xs={12} sm={3} key={item.labels}>
            <Grid container direction='column'>
              <Typography variant='body1' size='medium'>
                {item.labels}
              </Typography>

              {
                <TextField
                  size='small'
                  {...item}
                  required={true}
                  fullWidth={true}
                  {...register(`${item.form}`)}
                />
              }
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
