import React from 'react';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

const Style = () => ({
  tab: {
    '.MuiTab-root': {
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
      fontWeight: 300,
      color: 'secondary.light',
    },
    '.MuiTab-root.Mui-selected': {
      color: 'primary.light',
      fontWeight: 600,
      borderBottom: '1px solid #2D77AB',
    },
    '.MuiTabs-indicator': {
      display: 'block',
    },
  },
  tabLine: {
    borderBottom: 1,
    borderColor: 'divider',
    maxWidth: { xs: '89vw', sm: 'calc(91vw - 220px)', md: 'none' },
  },
});

const TabContent = ({ children, value, index, ...other }) => (
  <Grid role='tabpanel' hidden={value !== index} component='div' {...other}>
    {value === index && <Box sx={{ p: '40px 0px' }}>{children}</Box>}
  </Grid>
);

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export const BasicTabView = ({ tabPanel, ...others }) => {
  const [value, setValue] = React.useState(0);
  const classes = Style(tabPanel);

  const handleChange = (e, newValue) => {
    e.preventDefault();
    setValue(newValue);
  };

  return (
    <Box {...others}>
      <Box sx={classes.tabLine}>
        <Tabs
          sx={classes.tab}
          value={value}
          onChange={handleChange}
          indicatorColor='primary.main'
          textColor='primary'
          aria-label='tab content'
          scrollButtons={false}
          variant='scrollable'
          // allowScrollButtonsMobile
          {...others}
        >
          {tabPanel.map((items) => (
            <Tab
              sx={{
                marginRight: 2,
              }}
              key={items.tabTitle}
              label={<Typography variant='body1'>{items.tabTitle}</Typography>}
            />
          ))}
        </Tabs>
      </Box>
      {tabPanel.map((items, index) => (
        <TabContent
          value={value}
          index={index}
          key={`${items.tabTitle}_content`}
        >
          {items.tabContent}
        </TabContent>
      ))}
    </Box>
  );
};

BasicTabView.propTypes = {
  tabPanel: PropTypes.array,
  other: PropTypes.element,
};
