import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Divider,
  Typography,
  List,
  ListItemText,
  ButtonBase,
} from '@mui/material';
import { logo, shortLogo } from '../../constant/icons';

const items = [
  {
    label: '1. Category A',
    desc: 'faults is a No Go, the maintenance team must be  informed immediately.',
  },
  {
    label: '2. Category B',
    desc: 'fault must be brought to the workshop for repair immediately.',
  },
  {
    label: '3. Category C',
    desc: 'faults must be rectified by the end-user.',
  },
];

export const InspectionPrint = React.forwardRef(({ inspection }, ref) => {
  const questions =
    inspection.category_A !== null &&
    inspection.category_B !== null &&
    inspection.category_C !== null
      ? inspection.category_A.types.length +
        inspection.category_B.types.length +
        inspection.category_C.types.length
      : inspection.category.types.length;
  const [user, setUser] = React.useState('');

  const question = ({ label, value }) => {
    if (value === 'yes') {
      return (
        <Grid container justifyContent='space-between'>
          <Typography
            variant='body1'
            fontWeight={400}
            color={value === null && '#00000042'}
          >
            {label}
          </Typography>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'yes' ? '#ADAD0F' : null,
              color: value === 'yes' ? 'white' : null,
            }}
            variant='answerBtn'
          >
            Yes
          </ButtonBase>
        </Grid>
      );
    } else if (value === 'no') {
      return (
        <Grid container justifyContent='space-between'>
          <Typography
            variant='body1'
            fontWeight={400}
            color={value === null && '#00000042'}
          >
            {label}
          </Typography>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'no' ? '#2D77AB' : null,
              color: value === 'no' ? 'white' : null,
            }}
            variant='answerBtn'
          >
            No
          </ButtonBase>
        </Grid>
      );
    } else {
      return (
        <Grid container justifyContent='space-between'>
          <Typography
            variant='body1'
            fontWeight={400}
            color={value === null && '#00000042'}
          >
            {label}
          </Typography>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'n/a' ? 'gray' : null,
              color: value === 'n/a' ? 'white' : null,
            }}
            variant='answerBtn'
          >
            N/A
          </ButtonBase>
        </Grid>
      );
    }
  };

  const today = new Date().toLocaleDateString();

  const getUser = () => {
    const { authenticated } = JSON.parse(sessionStorage.getItem('user'));
    setUser(authenticated.displayName);
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div ref={ref}>
      <Grid
        container
        direction='column'
        gap={3}
        sx={{
          padding: '20px 40px 40px',
        }}
      >
        <Grid item container justifyContent={'center'}>
          <Box
            src={logo}
            component='img'
            sx={{
              width: '20%',
              height: '60px',
            }}
            alt='InspectFleet'
          />
        </Grid>
        <Grid item>
          <Box
            src={shortLogo}
            component='img'
            sx={{
              width: '5%',
              height: '40px',
            }}
            alt='short logo'
          />
        </Grid>
        <Grid item>
          <Typography variant='h5' color='primary'>
            Daily Vehicle Inspection Checklist
          </Typography>
        </Grid>

        <Grid item container direction='column' gap={1}>
          <Grid item container justifyContent='flex-end'>
            <Typography variant='body2' color='green'>
              {inspection.status}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              padding: '10px 20px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
            container
            direction='row'
          >
            <Grid
              item
              container
              justifyContent='space-between'
              alignItems={'center'}
              xs={8}
              sm={8}
            >
              <Typography variant='h6' color=' primary'>
                Score
              </Typography>
              <Typography variant='body1' color='primary'>
                {inspection.scores}% ( {inspection.total_items}/{questions})
              </Typography>
              &nbsp;
            </Grid>
            <Grid
              item
              container
              justifyContent='space-between'
              alignItems={'center'}
              xs={4}
              sm={4}
            >
              <Typography variant='h6' color=' primary'>
                Action
              </Typography>
              <Typography variant='body1' color=' primary'>
                0
              </Typography>
            </Grid>
          </Grid>
          <>
            <Grid
              item
              container
              direction='column'
              sx={{
                borderRadius: '10px',
                padding: '10px 10px 20px',
              }}
              gap={3}
            >
              <Grid item>
                <Typography variant='h6' color='primary'>
                  {inspection && inspection.vehicle_model}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                container
                direction='row'
                spacing={2}
                justifyContent='space-between'
              >
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Department
                  </Typography>
                  {inspection && (
                    <Typography variant='body2' color='primary'>
                      {inspection.department_name}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Divider
                    variant='fullWidth'
                    orientation='vertical'
                    flexItem
                    sx={{
                      height: '40px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Shift
                  </Typography>
                  <Typography variant='body2' color='primary'>
                    Day
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid item container direction='column' gap={2}>
                <Grid item>
                  <Typography variant='subtitle2' color='secondary.light'>
                    Site
                  </Typography>
                  {inspection && (
                    <Typography variant='body2' color='primary'>
                      {inspection.site}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
              >
                <Typography variant='body2' fontWeight={300} color='secondary'>
                  Conducted on
                </Typography>

                <Typography variant='body2' color='primary'>
                  {today}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                item
                container
                direction='row'
                justifyContent='space-between'
              >
                <Typography variant='body2' fontWeight={300} color='secondary'>
                  Prepared by
                </Typography>

                <Typography variant='body2' color='primary'>
                  {user}
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
        <Box height={400} />
        <Grid item container justifyContent={'center'}>
          <Box
            src={logo}
            component='img'
            sx={{
              width: '20%',
              height: '60px',
            }}
            alt='InspectFleet'
          />
        </Grid>
        <Grid item container direction='column' gap={3}>
          <Grid
            item
            sx={{
              padding: '10px 20px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Typography variant='h6' color=' primary'>
              Inspection List
            </Typography>
          </Grid>
          <Grid item container direction='column' gap={1}>
            <Grid item>
              <Typography>Instructions</Typography>
            </Grid>
            <Grid item>
              <List>
                {items.map((sub, index) => (
                  <ListItemText
                    sx={{
                      padding: '10px 10px',
                    }}
                    key={index}
                    primary={
                      <Typography variant='body1' color={'primary'}>
                        {sub.label}
                      </Typography>
                    }
                    secondary={
                      <Typography variant='body2' color={'secondary'}>
                        {sub.desc}
                      </Typography>
                    }
                  />
                ))}
              </List>
            </Grid>
          </Grid>
          {inspection?.category && (
            <>
              <Box height={650} />
              <Grid item container justifyContent={'center'}>
                <Box
                  src={logo}
                  component='img'
                  sx={{
                    width: '20%',
                    height: '60px',
                  }}
                  alt='InspectFleet'
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: '10px 20px',
                  backgroundColor: '#2d77ab1a',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='body1' color=' primary'>
                  Category
                </Typography>
              </Grid>

              <Grid
                item
                container
                direction='column'
                gap={1}
                sx={{
                  paddingInline: '10px',
                }}
              >
                {inspection.category.types.map((item) => (
                  <Grid item>
                    {question(item)}
                    <Divider sx={{ padding: '5px 0px' }} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {inspection?.category_A && (
            <>
              <Box height={650} />
              <Grid item container justifyContent={'center'}>
                <Box
                  src={logo}
                  component='img'
                  sx={{
                    width: '20%',
                    height: '60px',
                  }}
                  alt='InspectFleet'
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: '10px 20px',
                  backgroundColor: '#2d77ab1a',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='body1' color=' primary'>
                  Category A
                </Typography>
              </Grid>

              <Grid
                item
                container
                direction='column'
                gap={1}
                sx={{
                  paddingInline: '10px',
                }}
              >
                {inspection.category_A.types.map((item) => (
                  <Grid item>
                    {question(item)}
                    <Divider sx={{ padding: '5px 0px' }} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {inspection?.category_B && (
            <>
              <Box height={340} />
              <Grid item container justifyContent={'center'}>
                <Box
                  src={logo}
                  component='img'
                  sx={{
                    width: '20%',
                    height: '60px',
                  }}
                  alt='InspectFleet'
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: '10px 20px',
                  backgroundColor: '#2d77ab1a',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='body1' color=' primary'>
                  Category B
                </Typography>
              </Grid>

              <Grid
                item
                container
                direction='column'
                gap={1}
                sx={{
                  paddingInline: '10px',
                }}
              >
                {inspection.category_B.types.map((item) => (
                  <Grid item>
                    {question(item)}
                    <Divider sx={{ padding: '5px 0px' }} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {inspection?.category_C && (
            <>
              <Box height={80} />
              <Grid item container justifyContent={'center'}>
                <Box
                  src={logo}
                  component='img'
                  sx={{
                    width: '20%',
                    height: '60px',
                  }}
                  alt='InspectFleet'
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: '10px 20px',
                  backgroundColor: '#2d77ab1a',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='body1' color=' primary'>
                  Category C
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction='column'
                gap={1}
                sx={{
                  paddingInline: '10px',
                }}
              >
                {inspection.category_C.types.map((item) => (
                  <Grid item>
                    {question(item)}
                    <Divider sx={{ padding: '5px 0px' }} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Grid
            item
            sx={{
              padding: '10px 20px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Typography variant='body1' color=' primary'>
              Completion
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction='column'
            gap={1}
            sx={{
              paddingInline: '10px',
            }}
          >
            <Grid item>
              <Typography variant='body1' fontWeight={400} color='primary'>
                Driver name and Signature
              </Typography>
            </Grid>
            <Grid item container direction='row' spacing={3}>
              <Grid item>{inspection.user_fullname.substring(0, 4)}</Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Typography variant='body2' color='primary'>
                    {inspection.user_fullname}
                  </Typography>
                  <Typography variant='body2' color='primary'>
                    {inspection.createdAt}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: 2 }} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

// return (

// );
