import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@mui/material';
import { BsFiletypeCsv } from 'react-icons/bs';

const ExportReactCSV = ({ csvHeaders, csvData, fileName }) =>
  csvData.length > 0 ? (
    <CSVLink headers={csvHeaders} data={csvData} filename={fileName} style={{}}>
      <Button
        variant='outlined'
        disabled={csvData.length > 0 ? false : true}
        startIcon={<BsFiletypeCsv />}
      >
        CSV
      </Button>
    </CSVLink>
  ) : (
    <Button
      variant='outlined'
      disabled={csvData.length > 0 ? false : true}
      startIcon={<BsFiletypeCsv />}
    >
      CSV
    </Button>
  );

export default ExportReactCSV;
