/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  CircularProgress,
  Divider,
  Box,
} from '@mui/material';

import PropTypes from 'prop-types';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import IssueServices from '../../../service/issue';

const options = ['OPEN', 'APPROVED', 'REJECTED', 'RESOLVED'];

export const DetailItem = ({ toggleDrawer, issue }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: IssueServices.issue,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['issues'] });
    },
  });

  const handleClick = (event) => {
    event.preventDefault();
    mutate({ id: issue?.id, method: 'patch', status: options[selectedIndex] });
  };
  const handleMenuItemClick = (event, index) => {
    event.preventDefault();
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (issue?.status === 'OPEN') {
      return setSelectedIndex(0);
    } else if (issue?.status === 'APPROVED') {
      return setSelectedIndex(1);
    } else if (issue?.status === 'REJECTED') {
      return setSelectedIndex(2);
    } else if (issue?.status === 'RESOLVED') {
      return setSelectedIndex(3);
    }
    return null;
  }, []);

  return (
    <Grid container direction={'column'} gap={1}>
      <Grid
        item
        container
        direction={'column'}
        sx={{
          backgroundColor: '#FFF',
          padding: '10px 30px',
          borderRadius: '10px',
        }}
        gap={1}
      >
        <Grid item>
          <Typography variant='h5'>{issue?.name}</Typography>
        </Grid>
        <Divider />
        <Box height={20} />
        <Grid item container justifyContent='space-between'>
          <Typography variant='subtitle2'>Department</Typography>
          <Typography variant='body2' color='primary.light'>
            {issue?.department}
          </Typography>
        </Grid>
        <Divider />
        <Grid item container justifyContent='space-between'>
          <Typography variant='subtitle2'>Conducted by</Typography>
          <Typography variant='body2' color='primary.light'>
            {issue?.user_fullname}
          </Typography>
        </Grid>
        <Divider />
        <Grid item container justifyContent='space-between'>
          <Typography variant='subtitle2'>Date </Typography>
          <Typography variant='body2' color='primary.light'>
            {issue?.createdAt}
          </Typography>
        </Grid>
      </Grid>
      <Box height={20} />
      <Grid item>
        <Grid container direction='column' gap={1}>
          <Grid item>
            <Typography variant='body1'>What do you needs?</Typography>
          </Grid>
          <Grid
            item
            sx={{
              backgroundColor: '#FFF',
              padding: '15px',
              borderRadius: '10px',
            }}
          >
            <Typography variant='body2'>{issue?.needs}</Typography>
          </Grid>
          <Grid
            item
            sx={{
              backgroundColor: '#FFF',
              padding: '10px',
              borderRadius: '5px',
            }}
          >
            <Typography variant='body2'>{issue?.description}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction='column' gap={1}>
        <Grid item>
          <Typography variant='body2'>Status</Typography>
        </Grid>
        <Grid item>
          <ButtonGroup
            disableElevation={true}
            variant='contained'
            ref={anchorRef}
            aria-label='Button group with a nested menu'
          >
            <Button disableElevation variant='outlined' onClick={handleClick}>
              {options[selectedIndex]}
            </Button>
            <Button
              size='small'
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggle}
              variant='outlined'
              sx={{
                backgroundColor: 'transparent',
              }}
            >
              {isPending ? <CircularProgress /> : <ArrowDropDownIcon />}
            </Button>
          </ButtonGroup>
        </Grid>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

DetailItem.propTypes = {
  toggleDrawer: PropTypes.func,
  inspection: PropTypes.object,
};
