import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
export const Instruction = ({ items }) => (
  <Grid
    container
    direction='column'
    sx={{
      backgroundColor: '#2d77ab1a',
      borderRadius: '10px',
      padding: '20px 20px',
    }}
  >
    <Grid item>
      <Typography variant='body1' color='primary.light'>
        Instruction
      </Typography>
    </Grid>
    <Grid item>
      <List>
        {items.map((item, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={<Typography variant='body1'>{item.label}</Typography>}
              secondary={<Typography variant='body2'>{item.desc}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  </Grid>
);
