import { Grid, Typography } from '@mui/material';

export const Overview = ({ user }) => {
  return (
    <Grid container direction='column' gap={4}>
      <Grid item>
        <Typography variant='subtitle1'>Overview</Typography>
      </Grid>
      <Grid item container direction='column' gap={2}>
        <Typography variant='body2' color='secondary.light'>
          Status
          <Typography
            variant='body2'
            // component='span'
            sx={{
              color: user?.activated === true ? 'green' : 'red',
              fontWeight: 400,
            }}
          >
            {user?.activated === true ? 'Activated' : 'Deactivate'}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Created At
          <Typography variant='body2' color='primary.main'>
            {user?.createdAt}
          </Typography>
        </Typography>
        <Typography variant='body2' color='secondary.light'>
          Last login
          <Typography variant='body2' color='primary.main'>
            {user?.last_login}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
