import axiosInstance from './axios';

const addSpot = (data) => {
  return axiosInstance
    .post('spot/add/', { name: data.name, notes: data.notes })
    .then((res) => {
      return res.data;
    });
};

const allSpots = () => {
  return axiosInstance.get('spot/all/').then((res) => {
    return res.data.spot;
  });
};

const spot = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`spot/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance.patch(`spot/${id}/change/`, { data }).then((res) => {
      return res.data;
    });
  } else if (method === 'delete') {
    return axiosInstance.delete(`spot/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const SpotServices = {
  addSpot,
  allSpots,
  spot,
};

export default SpotServices;
