import React from 'react';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { RiFileExcel2Line } from 'react-icons/ri';

const ExcelExport = ({ data, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Button
      variant='outlined'
      onClick={exportToExcel}
      disabled={data.length > 0 ? false : true}
      startIcon={<RiFileExcel2Line />}
    >
      Excel
    </Button>
  );
};

export default ExcelExport;
