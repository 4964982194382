import { createTheme } from '@mui/material/styles';

// Defining a customer theme

const defaultTheme = createTheme();

const theme = createTheme(defaultTheme, {
  direction: 'ltr',
  palette: {
    mode: 'light',
    primary: {
      main: '#032842',
      light: '#2D77AB',
    },
    secondary: {
      main: '#000000',
      light: '#767676',
    },
    text: {
      fontFamily: 'noto sans',
      primary: '#032842',
      secondary: '#000000',
      disabled: 'gray',
    },

    background: {
      default: '#FFF',
      paper: '#C9C9C9',
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'noto sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          textDecoration: 'capitilize',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'noto sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          // paddingTop: '2px',
          // paddingBottom: '7px',
          borderRadius: '10px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'noto sans';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
        }
        body {
            margin: 0;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
			gap: 7;
          }
        ::-webkit-scrollbar {
            display: none;
        }
      `,
    },
    MuiButtonBase: {
      variants: [
        {
          props: { variant: 'answerBtn' },
          style: {
            borderRadius: '4px',
            letterSpacing: '0.02857em',
            fontSize: '14px',
            padding: '5px 15px',
            textTransform: 'uppercase',
            lineHeight: 1.75,
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            },
          },
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
  },
  typography: {
    h1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'extra-bold',
      fontSize: '47.78px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '39.81px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h3: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'semibold',
      fontSize: '33.18px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h4: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'regular',
      fontSize: '27.65px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h5: {
      fontFamily: 'noto sans',
      fontSize: '25px',
      fontStyle: 'medium',
      fontWeight: 500,
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    h6: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '19px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
    },
    body1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 300,
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    body2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    subtitle1: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'regular',
      fontSize: '16px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    subtitle2: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
    caption: {
      fontFamily: 'noto sans',
      fontStyle: 'normal',
      fontWeight: 'light',
      fontsize: '11px',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {},
      [defaultTheme.breakpoints.up('md')]: {},
      // lineHeight: '1.6rem',
    },
  },
});

export default theme;
