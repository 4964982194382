import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  useTheme,
  useMediaQuery,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

export const DialogBox = ({
  handleClose,
  open,
  title,
  content,
  maxWidth = '450px',
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            minWidth: maxWidth,
            backgroundColor: '#EBF1F7',
            borderRadius: '15px',
            // Set your width here
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: '#FFF',
          mb: 5,
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent>
        <Grid container direction={'column'} gap={4}>
          <Grid item xs={12} sm={12} md={12}>
            {content}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DialogBox.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.shape),
  ]),
  content: PropTypes.element,
  maxWidth: PropTypes.string,
};
