import axiosInstance from './axios';

const addShift = (data) => {
  return axiosInstance.post('shift/add/', { name: data.name }).then((res) => {
    return res.data;
  });
};

const allShift = () => {
  return axiosInstance.get('shift/all/').then((res) => {
    return res.data['data'];
  });
};

const shift = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`shift/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance.patch(`shift/${id}/change/`, { data }).then((res) => {
      return res.data;
    });
  } else if (method === 'delete') {
    return axiosInstance.delete(`shift/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const ShiftServices = {
  addShift,
  allShift,
  shift,
};

export default ShiftServices;
