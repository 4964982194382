import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuButtons } from '../../utilities/menuButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { DialogBox } from '../../utilities/dialog';
import { DeleteContent } from '../../../hooks/utils';
import AssetService from '../../../service/asset';
import { AddAsset } from './addAsset';
import { AssetFilterAverage } from './assetFilterAverage';
import { useNavigate } from 'react-router-dom';
import { AssetWithOutPrestart } from './assetWthoutPrestart';

const items = [
  {
    label: 'Edit',
    icon: <EditIcon fontSize='small' />,
  },
  {
    label: 'Delete',
    icon: <DeleteIcon fontSize='small' />,
  },
];

const csvCol = [
  {
    key: 'assetId',
    label: 'Asset Id',
  },
  {
    key: 'price',
    label: 'Fix Cost',
  },
  {
    key: 'smr',
    label: 'Start Smr',
  },

  {
    key: 'vinNumber',
    label: 'Vin Number',
  },
  {
    key: 'serviceInterval',
    label: 'Condition',
  },

  {
    key: 'departName',
    label: 'Department ',
  },
  {
    key: 'subDepartName',
    label: 'Sub Department',
  },
  {
    key: 'intervalName',
    label: 'Inspection Interval',
  },
  {
    key: 'year',
    label: 'Year Manufactured',
  },
  {
    key: 'ManufName',
    label: 'Manufacturer',
  },
  {
    key: 'modelName',
    label: 'Manufacturer Model',
  },
  {
    key: 'engineModel',
    label: 'Manufacturer Model',
  },
  {
    key: 'catName',
    label: 'Category',
  },
  {
    key: 'statusName',
    label: 'Status',
  },
  {
    key: 'target',
    label: 'Target',
  },
];

export const AssetComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [asset, setAsset] = React.useState();
  const [isAssetFilter, setIsAssetFilter] = React.useState(false);
  const [assetWithoutPrestartFilters, setAssetWithoutPrestartFilters] =
    React.useState(null);

  const [filtered, setFiltered] = React.useState(null);
  const [filterAssetData, setFilterAssetData] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ['Fetch_assets'],
    queryFn: () => AssetService.allAssets(),
  });
  const { mutate } = useMutation({
    mutationFn: AssetService.asset,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Fetch_assets'] });
      setIsDelete(false);
    },
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [setMobileOpens, setMobileOpenss] = React.useState(false);

  const toggleDrawer = () => {
    setMobileOpenss(!setMobileOpens);
  };
  const [isDialog, setIsDialog] = React.useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      field: 'assetId',
      headerName: 'Asset Id',
      width: 130,
    },
    {
      field: 'smrType',
      headerName: 'SMR Type',
      width: 180,
    },
    {
      field: 'previous_smr',
      headerName: 'Last reset',
      width: 140,
    },
    {
      field: 'count',
      headerName: 'Reset counts',
      width: 140,
    },
    {
      field: 'smr',
      headerName: 'Current SMR',
      width: 170,
    },

    {
      field: 'vinNumber',
      headerName: 'Vin Number',
      width: 150,
    },
    {
      field: 'serviceInterval',
      headerName: 'Service Interval',
      width: 180,
    },

    {
      field: 'departName',
      headerName: 'Department ',
      width: 150,
    },
    {
      field: 'subDepartName',
      headerName: 'Sub Department',
      width: 220,
    },
    {
      field: 'intervalName',
      headerName: 'Interval',
      width: 150,
    },
    {
      field: 'year',
      headerName: 'Year',
      width: 150,
    },
    {
      field: 'ManufName',
      headerName: 'Manufacturer',
      width: 150,
    },
    {
      field: 'modelName',
      headerName: 'Model',
      width: 150,
    },
    {
      field: 'engineModel',
      headerName: 'Engine Number',
      width: 200,
    },
    {
      field: 'catName',
      headerName: 'Category',
      width: 150,
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'target',
      headerName: 'Target',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
    },

    {
      field: '',
      headerName: '',
      width: 40,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];
  const handleMenuItem = (item, params) => {
    if (item.label === 'View asset') {
      toggleDrawer();
      setAnchorEl(null);
    } else if (item.label === 'Edit') {
      setAnchorEl(null);
      setIsDialog(() => true);
    } else if (item.label === 'Delete') {
      setAnchorEl(null);
      setIsDelete(true);
    } else {
      return null;
    }
  };

  const assetInspectionCol = [
    {
      field: 'inspectionId',
      headerName: 'Inspection ID',
      width: 170,
    },
    {
      field: 'asset',
      headerName: 'Assets',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              (params.value === 'PENDING' && '#002b924f') ||
              (params.value === 'COMPLETED' && '#0092844f') ||
              (params.value === 'VERIFIED' && '#7092004f') ||
              (params.value === 'DUPLICATED' && '#bcbd004f') ||
              (params.value === 'REJECTED' && '#bd00004f'),
            padding: '5px 15px',
            minWidth: '85%',
            borderRadius: '5px',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'mileage',
      headerName: 'Mileage',
      width: 120,

      renderCell: (params) => {
        if (params.value === null) {
          return 'None';
        }
        return params.value;
      },
    },
    {
      field: 'hours',
      headerName: 'Hours',
      width: 120,

      renderCell: (params) => {
        if (params.value === null) {
          return 'None';
        }
        return params.value;
      },
    },

    {
      field: 'department_name',
      headerName: 'Department',
      width: 170,
    },
    {
      field: 'shift',
      headerName: 'Shift',
      width: 140,
    },
    {
      field: 'user_fullname',
      headerName: 'User',
      width: 140,
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      width: 240,
    },
  ];

  const {
    mutate: assetWithoutPrestart,
    isPending: assetWithoutPrestartPending,
  } = useMutation({
    mutationFn: AssetService.assetsWithNoPrestart,
    onSuccess: (data) => {
      setAssetWithoutPrestartFilters(data);
    },
  });

  const { mutate: assetFilters, isPending: assetFilterPending } = useMutation({
    mutationFn: AssetService.allAssetFiltered,
    onSuccess: (data) => {
      setFilterAssetData(data);
    },
  });
  return (
    <>
      <MainContent
        csvData={data}
        csvHeaders={csvCol}
        csvDataWithFilter={filterAssetData || filtered || data}
        csvHeadersWithFilter={csvCol}
        fileNameWithFilter={'Assert list'}
        fileName={'Assert list'}
        valueFrom='add_asset'
        isFilter={true}
        isFilter2={true}
        clearData={() => {
          setFilterAssetData(null);
          setIsAssetFilter(false);
          setAssetWithoutPrestartFilters(null);
        }}
        filterContent={
          <AssetFilterAverage
            filterAssetData={filterAssetData}
            assetFilters={assetFilters}
          />
        }
        filterContent2={
          <AssetWithOutPrestart
            assetFilters={assetWithoutPrestart}
            callback={setIsAssetFilter}
          />
        }
        btnFilter='Calculate asset average'
        btnFilter2='asset without prestart'
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setFiltered(() => data);
            return;
          }
          const value = data.filter(
            (item) => item.assetId.toLowerCase().indexOf(val.target.value) > -1
          );
          setFiltered(() => value);
        }}
        title='DataSheet Asset lists'
        items={
          <TableData
            cols={
              filterAssetData
                ? isAssetFilter
                  ? columns
                  : assetInspectionCol
                : columns
            }
            rows={
              filterAssetData?.inspections ||
              assetWithoutPrestartFilters ||
              filtered ||
              data
            }
            height='500px'
            isLoading={
              assetFilterPending || assetWithoutPrestartPending || isLoading
            }
            onRowClick={({ row }) => setAsset(() => row)}
            onCellClick={({ field, row }) => {
              if (field !== '') {
                navigate(`/asset/${row.id}`);
              }
            }}
          />
        }
        isBtnName={true}
        btnName={'Add'}
        onBtnNameClick={() => {
          setAsset(null);
          setIsDialog(true);
        }}
      />
      <DialogBox
        maxWidth='500px'
        handleClose={() => {
          setAsset(null);
          setIsDialog(false);
        }}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Create new Asset
          </Typography>
        }
        content={
          <AddAsset handleClose={() => setIsDialog(false)} asset={asset} />
        }
        submit={'Submit'}
      />
      <DialogBox
        handleClose={() => {
          setAsset(null);
          setIsDelete(false);
        }}
        open={isDelete}
        maxWidth='350px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete asset
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this asset ?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = asset.id;
              const method = 'delete';
              mutate({ id, method });
            }}
          />
        }
      />
    </>
  );
};
