import React from 'react';
import Layout from '../layout';
import { Outlet, Navigate } from 'react-router-dom';

export const ProtectedRoute = () => {
  const user = sessionStorage?.getItem('user') ?? null;

  if (!user) {
    return <Navigate to='/login' />;
  } else {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
};
