import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Alert from '../../constant/alert';
import DepartmentServices from '../../../service/department';
import CustomPaper from '../../utilities/customPaper';
import AssetService from '../../../service/asset';
import AutoCompleteDefault from '../../../hooks/autoCompleteFetch';

const inputField = [
  {
    label_name: 'Asset Id',
    form: 'assetId',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Vin Number',
    form: 'vinNumber',
    type: 'text',
    required: true,
  },

  {
    label_name: 'Start Smr',
    form: 'smr',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Purchase Price',
    form: 'price',
    type: 'number',
    required: true,
  },
  {
    label_name: 'Engine Model',
    form: 'engineModel',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Service Interval',
    form: 'serviceInterval',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Service Cycle',
    form: 'serviceCycle',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Year Manufacture',
    form: 'year',
    type: 'text',
    required: true,
  },
];

export const AddAsset = ({ handleClose, asset }) => {
  const [user, setUser] = React.useState({
    id: null,
    fullname: null,
  });
  const [depart, setDepart] = React.useState(null);
  const [subDepart, setSubDepart] = React.useState(null);
  const [manuf, setManuf] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [model, setModel] = React.useState(null);
  const [interval, setInterval] = React.useState(null);
  const [cat, setCat] = React.useState(null);
  const [smrType, setSmrType] = React.useState(null);
  const [target, setTarget] = React.useState(null);

  const queryClient = useQueryClient();
  const { handleSubmit, register, reset } = useForm({
    defaultValues: asset,
  });
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: asset ? AssetService.asset : AssetService.addAsset,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Fetch_assets'] });
      reset();
    },
  });

  const { data: department, isLoading: isLoadingDepartment } = useQuery({
    queryKey: ['assetDepartment'],
    queryFn: () => DepartmentServices.allDepartments(),
  });

  const { data: dataModel, isLoading: isLoadingModel } = useQuery({
    queryKey: ['assetModel'],
    queryFn: () => AssetService.allAssetLabel('model'),
  });
  const { data: dataStatus, isLoading: isLoadingStatus } = useQuery({
    queryKey: ['assetStatus'],
    queryFn: () => AssetService.allAssetLabel('status'),
  });
  const { data: dataInterval, isLoading: isLoadingInterval } = useQuery({
    queryKey: ['assetInterval'],
    queryFn: () => AssetService.allAssetLabel('interval'),
  });
  const { data: dataCategory, isLoading: isLoadingCategory } = useQuery({
    queryKey: ['assetCategory'],
    queryFn: () => AssetService.allAssetLabel('category'),
  });
  const { data: dataManufacture, isLoading: isLoadingManufacture } = useQuery({
    queryKey: ['assetManuf'],
    queryFn: () => AssetService.allAssetLabel('manufacture'),
  });

  const { data: subDepartment, isLoading: isLoadingSubDepartment } = useQuery({
    queryKey: ['Fetch_sub_department', depart?.id],
    queryFn: depart
      ? async () => {
          const id = depart?.id;
          const subDep = await DepartmentServices.subDepartmentByDepartment({
            id,
          });
          return subDep;
        }
      : [],
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const {
      assetId,
      vinNumber,
      smr,
      price,
      engineModel,
      serviceInterval,
      serviceCycle,
      year,
    } = data;
    if (asset) {
      mutate({
        assetId,
        vinNumber,
        smr,
        engineModel,
        price,
        serviceInterval,
        serviceCycle,
        year,
        cat: cat || asset.cat,
        department: depart?.id || asset.department,
        subDepartment: subDepart?.id || asset.subDepartment,
        manufacture: manuf?.id || asset.manufacture,
        model: model?.id || asset.model,
        status: status?.id || asset.status,
        interval: interval?.id || asset.interval,
        target: parseInt(target) || asset.target,
        smrType: smrType || asset.smrType,
        id: asset.id,
        method: 'patch',
      });
    } else {
      mutate({
        assetId,
        vinNumber,
        smr,
        engineModel,
        price,
        serviceInterval,
        serviceCycle,
        year,
        category: cat.id,
        department: depart.id,
        subDepartment: subDepart.id,
        manufacture: manuf.id,
        model: model.id,
        status: status.id,
        interval: interval.id,
        target: parseInt(target),
        smrType,
        user: user.id,
      });
    }
  };

  const getUser = () => {
    const { authenticated } = JSON.parse(sessionStorage.getItem('user')) ?? {};
    setUser({
      id: authenticated.id,
      fullname: authenticated.fullname,
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={2}>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              {inputField.map((value, i) => (
                <Grid
                  item
                  container
                  direction={'column'}
                  gap={1}
                  key={i}
                  xs={12}
                  sm={6}
                >
                  <Typography variant='subtitle2'>
                    {value.label_name}
                  </Typography>
                  <TextField
                    {...value}
                    {...register(`${value.form}`)}
                    sx={{
                      backgroundColor: '#FFF',
                    }}
                    size='small'
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Category</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={dataCategory ? dataCategory : []}
              loading={isLoadingCategory}
              value={
                cat ||
                (asset &&
                  dataCategory &&
                  AutoCompleteDefault(dataCategory, asset.category)) ||
                null
              }
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setCat(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Department</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={department ? department : []}
              value={
                depart ||
                (asset &&
                  department &&
                  AutoCompleteDefault(department, asset.department)) ||
                null
              }
              loading={isLoadingDepartment}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setDepart(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          {depart && (
            <Grid item container direction='column'>
              <Typography variant='subtitle2'> Sub Department</Typography>
              <Autocomplete
                id='combo-box-demo'
                size='small'
                loading={isLoadingSubDepartment}
                options={subDepartment ? subDepartment : []}
                value={subDepart}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setSubDepart(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          )}
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Manufacture</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              loading={isLoadingManufacture}
              options={dataManufacture ? dataManufacture : []}
              value={
                manuf ||
                (asset &&
                  dataManufacture &&
                  AutoCompleteDefault(dataManufacture, asset.manufacture)) ||
                null
              }
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setManuf(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Manufacture Model</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={dataModel ? dataModel : []}
              loading={isLoadingModel}
              value={
                model ||
                (asset &&
                  dataModel &&
                  AutoCompleteDefault(dataModel, asset.model)) ||
                null
              }
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setModel(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Operational status</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={dataStatus ? dataStatus : []}
              loading={isLoadingStatus}
              value={
                status ||
                (asset &&
                  dataStatus &&
                  AutoCompleteDefault(dataStatus, asset.status)) ||
                null
              }
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>Inspection Interval</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={dataInterval ? dataInterval : []}
              value={
                interval ||
                (asset &&
                  dataInterval &&
                  AutoCompleteDefault(dataInterval, asset.interval)) ||
                null
              }
              loading={isLoadingInterval}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setInterval(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>
              Target Prestart (Monthly)
            </Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={[
                '5',
                '10',
                '15',
                '20',
                '25',
                '30',
                '35',
                '40',
                '45',
                '50',
                '55',
                '60',
              ]}
              value={target || (asset && asset?.target.toString()) || null}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setTarget(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item container direction='column'>
            <Typography variant='subtitle2'>SMR Type</Typography>

            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={['KM', 'HRS', 'DAYS']}
              value={smrType || (asset && asset?.smrType) || null}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setSmrType(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error.message}, Please contact the management team
              </Typography>
            )}
          </Grid>

          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: '#2D77AB',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2D77AB',
                  },
                }}
                variant='contained'
                type='submit'
              >
                {asset ? 'Update' : 'Save'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
AddAsset.propTypes = {
  handleClose: PropTypes.func,
  asset: PropTypes.objectOf(PropTypes.shape),
};
