import React from 'react';
import { CheckList } from './checklist';
import {
  itemsTruckA,
  itemsTruckB,
  itemsTruckC,
} from '../../../utilities/question';
export const TruckCheckList = () => {
  const asset = {
    'Category A': [],
    'Category B': [],
    'Category C': [],
    'Extra details': [],
  };

  return (
    <CheckList
      form='truck'
      itemsA={itemsTruckA}
      itemsB={itemsTruckB}
      itemsC={itemsTruckC}
      asset={asset}
    />
  );
};
