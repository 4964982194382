import axiosInstance from './axios';

const addService = (data) => {
  console.log(data);

  return axiosInstance
    .post('service/add/', {
      assetId: data.assetId,
      category: data.category,
      last_service_smr: data.last_service_smr,
      department: data.department,
      sub_department: data.subDepartment,
      manufacturer: data.manufacture,
      job_card_number: data.job_card_number,
      comments: data.comments,
      serviced_date: data.serviced_date,
      user: data.user,
      service_type: data.serviceType,
    })
    .then((res) => {
      return res.data;
    });
};

const allService = () => {
  return axiosInstance.get('service/all/').then((res) => {
    return res.data.all;
  });
};
const allServiceFiltered = ({ site, department, subDepartment, category }) => {
  return axiosInstance
    .post(
      `service/all/?site=${site}&department=${department}&subDepartment=${subDepartment}&category=${category}`
    )
    .then((res) => {
      return res.data;
    });
};

const service = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`service/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`service/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`service/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const serviceStatus = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`service/category/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`service/category/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`service/category/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};

const serviceServices = {
  addService,
  allService,
  service,
  serviceStatus,
  allServiceFiltered,
};

export default serviceServices;
