import React from 'react';
import { CheckList } from './checklist';
import {
  itemsFolkA,
  itemsFolkB,
  itemsFolkC,
} from '../../../utilities/question';

export const FolkCheckList = () => {
  const asset = {
    'Category A': [],
    'Category B': [],
    'Category C': [],
    'Extra details': [],
  };

  return (
    <CheckList
      form='folk'
      itemsA={itemsFolkA}
      itemsB={itemsFolkB}
      itemsC={itemsFolkC}
      asset={asset}
    />
  );
};
