import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const DeleteContent = ({ title, onCancel, onSubmit, setIsSmr }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Grid container direction='column' gap={5}>
      <Grid item>
        <Typography variant='body2'>{title}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Button onClick={onCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loading}
              sx={{
                textTransform: 'capitalize',
              }}
              variant='contained'
              autoFocus
              onClick={() => {
                onSubmit();
                setLoading(() => true);
              }}
            >
              Delete
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Content = ({
  title,
  onCancel,
  onSubmit,
  name,
  isPending,
  error,
  setIsSmr,
}) => {
  // const [loading, setLoading] = useState(false);
  return (
    <Grid container direction='column' gap={5}>
      <Grid item>
        <Typography variant='body2'>{title}</Typography>
      </Grid>
      {error && (
        <Grid item alignSelf='flex-end' xs={12}>
          <Typography variant='caption' color='red'>
            {error?.message}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Button onClick={onCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isPending}
              sx={{
                textTransform: 'capitalize',
              }}
              variant='contained'
              autoFocus
              onClick={() => {
                onSubmit();

                if (!error) {
                  setIsSmr(() => false);
                }
              }}
            >
              {name}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
