/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import UserServices from '../../../service/user';
import { useQueryClient } from '@tanstack/react-query';

export const Settings = ({ user }) => {
  const queryClient = useQueryClient();
  const [isEditDetails, setisEditDetails] = React.useState(false);
  const [isPhone, setisPhone] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutate } = useMutation({
    mutationFn: UserServices.updateUser,
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setisEditDetails(false);
      setisPhone(false);
      setIsLoading(false);
    },
  });

  const { handleSubmit, register, reset } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault();

    setisEditDetails(true);
    setIsLoading(true);
    mutate({
      id: data.id,
      display_name: data.display_name,
      email: data.email,
      idnumber: data.idnumber,
      phone: data.phone,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container direction='column' spacing={5}>
            <Grid item>
              <Paper
                elevation={0}
                sx={{
                  padding: '20px 30px',
                  backgroundColor: '#2d77ab1a',
                  borderRadius: '10px',
                }}
              >
                <Grid container direction='column' gap={2}>
                  <Grid item>
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-between'
                    >
                      <Grid item>
                        <Typography variant='h6'>Details</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction='row' gap={2}>
                          {isEditDetails === true && (
                            <Button
                              sx={{
                                height: 30,
                                border: '1px solid #2D77AB',
                              }}
                              type='submit'
                              variant='outlined'
                              startIcon={
                                isLoading ? <CircularProgress /> : <SaveIcon />
                              }
                              disableElevation
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            sx={{
                              height: 30,
                              border: '1px solid #2D77AB',
                            }}
                            variant='outlined'
                            onClick={() => {
                              if (isEditDetails === true) {
                                setisEditDetails(false);
                              } else {
                                reset();
                                setisEditDetails(true);
                              }
                            }}
                            startIcon={
                              isEditDetails === true ? (
                                <CloseIcon />
                              ) : (
                                <EditIcon fontSize='10px' />
                              )
                            }
                            disableElevation
                          >
                            {isEditDetails === true ? 'Close' : 'Edit'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' color='secondary.light'>
                      Display Name
                    </Typography>
                    {isEditDetails ? (
                      <TextField
                        variant='filled'
                        sx={{
                          backgroundColor: '#FFF',
                        }}
                        required
                        {...register('display_name')}
                        fullWidth
                        size='small'
                      />
                    ) : (
                      <Typography variant='body1' sx={{ fontWeight: 400 }}>
                        {user === undefined ? '--' : user.display_name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' color='secondary.light'>
                      Email
                    </Typography>
                    {isEditDetails ? (
                      <TextField
                        variant='filled'
                        required
                        {...register('email')}
                        fullWidth
                        size='small'
                      />
                    ) : (
                      <Typography variant='body1' sx={{ fontWeight: 400 }}>
                        {user === undefined ? '--' : user.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' color='secondary.light'>
                      Idnumber
                    </Typography>
                    {isEditDetails ? (
                      <TextField
                        variant='filled'
                        required
                        {...register('idnumber')}
                        fullWidth
                        size='small'
                      />
                    ) : (
                      <Typography variant='body1' sx={{ fontWeight: 400 }}>
                        {user === undefined ? '--' : user.idnumber}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={0}
            sx={{
              padding: '20px 30px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Grid container direction='column' gap={2}>
              <Grid container direction='row' justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h6'>Phone number</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction='row' gap={2}>
                    {isPhone === true && (
                      <Button
                        sx={{
                          height: 30,
                          border: '1px solid #2D77AB',
                        }}
                        disableElevation
                        type='submit'
                        variant='outlined'
                        startIcon={
                          isLoading ? <CircularProgress /> : <SaveIcon />
                        }
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      sx={{
                        height: 30,
                        border: '1px solid #2D77AB',
                      }}
                      disableElevation
                      onClick={() => {
                        if (isPhone === true) {
                          setisPhone(false);
                        } else {
                          reset(user);
                          setisPhone(true);
                        }
                      }}
                      variant='outlined'
                      startIcon={
                        isPhone === true ? (
                          <CloseIcon />
                        ) : (
                          <EditIcon fontSize='10px' />
                        )
                      }
                    >
                      {isPhone === true ? 'Close' : 'Edit'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {isPhone ? (
                  <TextField
                    variant='filled'
                    required
                    {...register('phone')}
                    fullWidth
                    size='small'
                  />
                ) : (
                  <Typography variant='subtitle2'>
                    {user === undefined ? '--' : user.phone}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

Settings.propTypes = {
  user: PropTypes.objectOf(PropTypes.shape),
};
