import React from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
export const ExtraDetails = ({ callback, category, label, question }) => {
  const [isNote, setIsNote] = React.useState(false);
  const [note, setNote] = React.useState({ text: '', isActive: false });

  const handleSave = () => {
    console.log(note.text);

    callback((prev) => ({
      ...prev,
      [category]: prev[category].map((item) =>
        Object.keys(item)[0] === label ? { ...item, note: note.text } : item
      ),
    }));
    setNote((prev) => ({
      ...prev,
      isActive: true,
    }));
    setIsNote(false);
  };

  console.log(note.text);
  return (
    <Grid container direction='column' gap={1}>
      {note.isActive && (
        <Grid
          item
          sx={{
            padding: '20px 10px',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
        >
          <Typography>{note.text}</Typography>
        </Grid>
      )}

      {!note.isActive && (
        <Grid item>
          {isNote === false ? (
            <Button
              startIcon={<CommentIcon />}
              disabled={question ? false : true}
              onClick={() => setIsNote(true)}
              sx={{
                textTransform: 'capitalize',
              }}
            >
              Note
            </Button>
          ) : (
            <TextField
              size='small'
              name='note'
              multiline={true}
              fullWidth
              rows={2}
              onChange={(e) => {
                setNote({
                  text: e.target.value,
                  isActive: false,
                });
              }}
              placeholder='Add a note'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => handleSave()}
                      edge='end'
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
