/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Questions } from './question';

const CategoryView = ({ category, title, isPrinting }) => {
  const [isA, setIsA] = React.useState(false);
  const { total, types } = category;

  useEffect(() => {
    setIsA(isPrinting);
  }, []);
  return (
    <Grid item container direction={'column'} gap={3}>
      <Grid item container justifyContent='space-between'>
        <Grid item container xs={11} sm={12} md={11}>
          <Button
            startIcon={
              isA ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
            }
            sx={{
              textTransform: 'capitalize',
            }}
            onClick={() => setIsA(!isA)}
          >
            {title}
          </Button>
        </Grid>
        <Grid item container justifyContent='flex-end' xs={11} sm={11} md={1}>
          {category && (
            <>
              <Typography variant='body2' color='primary'>
                {total}/{types.length}
              </Typography>
              <Typography variant='body2' color='primary'>
                ({Math.round(((total * 100) / types.length) * 100) / 100})
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      {isA && (
        <Grid item container flexDirection='column' gap='20px'>
          {types.map(({ label, value }) => (
            <Questions label={label} value={value} key={label} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default CategoryView;
