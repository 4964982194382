import { Grid, Button, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ text, handleClose }) => {
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      gap={4}
    >
      <Grid item>
        <Typography variant='subtitble1' color='primary.light'>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            textTransform: 'capitalize',
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

Alert.propTypes = {
  text: PropTypes.string,
  handleClose: PropTypes.func,
};

export default Alert;
