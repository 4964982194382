import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const TableData = ({
  cols,
  height,
  rows = [],
  onRowClick,
  isLoading,
  onCellClick,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: {
          xs: '89vw',
          sm: 'calc(92vw - 220px)',
          md: 'calc(86vw - 220px)',
          lg: 'calc(90vw - 220px)',
        },
        height: height,
      }}
    >
      <DataGrid
        rows={rows}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        loading={isLoading}
        // disableSelectionOnClick
        // checkboxSelection
        // scrollbarSize={50}
        disableColumnFilter={true}
        columns={cols}
        sx={{
          overflow: 'hidden',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#5f95bfa6',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#5f95bfa6',
          },
          '& .MuiDataGrid-row:nth-of-type(2n)': {
            backgroundColor: '#5f95bf33',
          },
          '& .MuiDataGrid-withBorderColor': {
            borderColor: '#03284233',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            color: 'primary.main',
            fontSize: 14,
            fontWeight: 500,
          },
          // border: 'none',
          fontSize: 14,
          fontWeight: 300,
          color: 'primary.main',
          // cursor: 'pointer',
          boxShadow: '0px 0px 8px 4px #cdcccc4d',
          backgroundColor: '#ffffff3d',
          backdropFilter: 'blur(15px)',
          // borderRadius: '5px',
        }}
      />
    </Box>
  );
};
TableData.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default TableData;
