import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';

export const EditView = () => {
	const navigate = useNavigate();
	return (
		<Grid
			container
			direction="column"
			sx={{
				padding: { sm: '20px 60px' },
			}}
		>
			<Grid item container direction="row" spacing={2}>
				<Grid item xs={12} sm={8}>
					<Grid container direction="row" gap={1} alignItems="center">
						<IconButton onClick={() => navigate('/inspection')}>
							<KeyboardBackspaceOutlinedIcon />
						</IconButton>
						<Typography variant="h6">12022024 - CO2456</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item container></Grid>
		</Grid>
	);
};
