import React, { useEffect } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { QuestionView } from './question';
import { useQuery } from '@tanstack/react-query';
import CustomPaper from '../../../utilities/customPaper';
import ShiftServices from '../../../../service/shift';
import {
  extraMachineForm,
  extraVehicleForm,
} from '../../../utilities/question';
import AssetService from '../../../../service/asset';
import DepartmentServices from '../../../../service/department';
import UserServices from '../../../../service/user';

export const Category = ({
  category,
  data,
  score,
  info,
  callback,
  form,
  assetData,

  cat,
}) => {
  const [shift, setShift] = React.useState(null);
  const [assetKm, setAssetKm] = React.useState(null);
  const [assetHrs, setAssetHrs] = React.useState(null);

  const [error, setError] = React.useState(false);

  const [depart, setDepart] = React.useState(null);

  const { data: shifts, isLoading: isShift } = useQuery({
    queryKey: ['shift_list'],
    queryFn: () => ShiftServices.allShift(),
  });

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_assets'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      return resp;
    },
  });
  const { data: users, isLoading: isUser } = useQuery({
    queryKey: ['user_department', depart?.id],
    queryFn: depart
      ? async () => {
          const id = depart?.id;
          const resp = await UserServices.userByDepartment({
            id: id,
          });

          return resp;
        }
      : [],
  });

  const { data: assetDepartmentKM, isLoading: isLoadingAssetDepartmentKM } =
    useQuery({
      queryKey: ['sub_assets_km', depart?.id],
      queryFn: depart
        ? async () => {
            const id = depart?.id;
            const resp = await AssetService.getAllAssetByDepartmentAndSmr({
              department: id,
              smr_type: 'KM',
            });
            return resp;
          }
        : [],
    });
  const { data: assetDepartmentHrs, isLoading: isLoadingAssetDepartmentHrs } =
    useQuery({
      queryKey: ['sub_assets_hrs', depart?.id],
      queryFn: depart
        ? async () => {
            const id = depart?.id;
            const resp = await AssetService.getAllAssetByDepartmentAndSmr({
              department: id,
              smr_type: 'HRS',
            });
            return resp;
          }
        : [],
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cat]);

  return (
    <Grid container direction='column' gap={4}>
      <Grid item container direction='row' justifyContent='space-between'>
        <Typography variant='h6'>{category}</Typography>
        <Typography variant='body2'>{score}</Typography>
      </Grid>

      <Grid item>
        {data && (
          <Grid container direction='row' gap={3} alignItems='flex-start'>
            {data?.map((item, i) => (
              <Grid item key={i}>
                <QuestionView
                  item={item}
                  callback={callback}
                  category={category}
                  inspectionData={assetData}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {info && (
        <Grid item container direction='column' gap={2}>
          <Grid
            item
            container
            sx={{
              padding: '20px 20px',
              boxShadow: '0px 0px 20px 5px #cdcdcd4d',
              backgroundColor: '#ffffff3d',
              backdropFilter: 'blur(20px)',
              borderRadius: '10px',
            }}
          >
            <Typography variant='subtitle2'>Shift</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={!isShift ? shifts : []}
              value={shift}
              fullWidth={true}
              loading={isShift}
              getOptionLabel={(option) => option.name}
              PaperComponent={CustomPaper}
              onChange={(event, newValue) => {
                setShift(newValue);
                callback((prev) => ({
                  ...prev,
                  [category]: {
                    ...prev[category],
                    shift: newValue?.id,
                  },
                }));
              }}
              renderInput={(params) => (
                <TextField required {...params} size='small' />
              )}
            />
          </Grid>
          <Grid
            item
            container
            sx={{
              padding: '20px 20px',
              boxShadow: '0px 0px 20px 5px #cdcdcd4d',
              backgroundColor: '#ffffff3d',
              backdropFilter: 'blur(20px)',
              borderRadius: '10px',
            }}
          >
            <Typography variant='subtitle2'>Department</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={!isDepartment ? departments : []}
              value={depart}
              fullWidth={true}
              loading={isDepartment}
              getOptionLabel={(option) => option.name}
              PaperComponent={CustomPaper}
              onChange={(event, newValue) => {
                setDepart(newValue);
                callback((prev) => ({
                  ...prev,
                  [category]: {
                    ...prev[category],
                    department: newValue?.id,
                  },
                }));
              }}
              renderInput={(params) => (
                <TextField required {...params} size='small' />
              )}
            />
          </Grid>
          {depart?.id > 0 &&
            (form === 'vehicle' || form === 'folk' || form === 'truck' ? (
              <Grid
                item
                container
                sx={{
                  padding: '20px 20px',
                  boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                  backgroundColor: '#ffffff3d',
                  backdropFilter: 'blur(20px)',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='subtitle2'>Asset</Typography>
                <Autocomplete
                  id='combo-box-demo'
                  size='small'
                  disabled={depart?.id !== 0 ? false : true}
                  options={!isLoadingAssetDepartmentKM ? assetDepartmentKM : []}
                  value={assetKm}
                  fullWidth={true}
                  loading={isLoadingAssetDepartmentKM}
                  getOptionLabel={(option) => option.assetId}
                  PaperComponent={CustomPaper}
                  onChange={(event, newValue) => {
                    setAssetKm(newValue);
                    callback((prev) => ({
                      ...prev,
                      [category]: {
                        ...prev[category],
                        assetKm: newValue?.id,
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} required size='small' />
                  )}
                />
              </Grid>
            ) : (
              <Grid
                item
                container
                sx={{
                  padding: '20px 20px',
                  boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                  backgroundColor: '#ffffff3d',
                  backdropFilter: 'blur(20px)',
                  borderRadius: '10px',
                }}
              >
                <Typography variant='subtitle2'>Asset</Typography>
                <Autocomplete
                  id='combo-box-demo'
                  size='small'
                  disabled={depart?.id !== 0 ? false : true}
                  options={
                    !isLoadingAssetDepartmentHrs ? assetDepartmentHrs : []
                  }
                  value={assetHrs}
                  fullWidth={true}
                  loading={isLoadingAssetDepartmentHrs}
                  getOptionLabel={(option) => option.assetId}
                  PaperComponent={CustomPaper}
                  onChange={(event, newValue) => {
                    setAssetHrs(newValue);
                    callback((prev) => ({
                      ...prev,
                      [category]: {
                        ...prev[category],
                        assetHrs: newValue?.id,
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} required size='small' />
                  )}
                />
              </Grid>
            ))}
          {(assetKm?.id || assetHrs?.id) &&
            (form === 'vehicle' || form === 'folk' || form === 'truck' ? (
              <Grid item container gap={4}>
                {extraVehicleForm.map((item, i) => (
                  <Grid
                    item
                    container
                    gap={1}
                    direction='column'
                    key={i}
                    xs={12}
                    sm={12}
                    sx={{
                      padding: '20px 20px',
                      boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                      backgroundColor: '#ffffff3d',
                      backdropFilter: 'blur(20px)',
                      borderRadius: '10px',
                    }}
                  >
                    <Grid item>
                      <Typography variant='subtitle2'>
                        {item.title === 'Mileage'
                          ? `Current mileage is ${assetKm?.smr}`
                          : item.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        {...item}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (item.title === 'Mileage') {
                            if (value >= assetKm.smr) {
                              callback((prev) => ({
                                ...prev,
                                [category]: {
                                  ...prev[category],
                                  [item.title]: event.target.value,
                                },
                              }));
                              setError(false);
                            } else {
                              setError(true);
                            }
                          } else {
                            callback((prev) => ({
                              ...prev,
                              [category]: {
                                ...prev[category],
                                [item.title]: event.target.value,
                              },
                            }));
                          }
                        }}
                        error={item.title === 'Mileage' && error === true}
                        helperText={
                          item.title === 'Mileage' &&
                          error === true &&
                          'Mileage must be greater than the current mileage'
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item container gap={4}>
                {extraMachineForm.map((item, i) => (
                  <Grid
                    item
                    container
                    key={i}
                    direction='column'
                    xs={12}
                    sm={12}
                    gap={1}
                    sx={{
                      padding: '20px 20px',
                      boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                      backgroundColor: '#ffffff3d',
                      backdropFilter: 'blur(20px)',
                      borderRadius: '10px',
                    }}
                  >
                    <Grid item>
                      <Typography variant='subtitle2'>
                        {item.title === 'Hours'
                          ? `Current hours is  ${assetHrs?.smr}`
                          : item.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        {...item}
                        onChange={(event) => {
                          const value = event.target.value;
                          if (item.title === 'Hours') {
                            if (value >= assetHrs.smr) {
                              callback((prev) => ({
                                ...prev,
                                [category]: {
                                  ...prev[category],
                                  [item.title]: event.target.value,
                                },
                              }));
                              setError(false);
                            } else {
                              setError(true);
                            }
                          } else {
                            callback((prev) => ({
                              ...prev,
                              [category]: {
                                ...prev[category],
                                [item.title]: event.target.value,
                              },
                            }));
                          }
                        }}
                        error={item.title === 'Hours' && error === true}
                        helperText={
                          item.title === 'Hours' &&
                          error === true &&
                          'Hours must be greater than the current Hours'
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          {depart?.id > 0 && (
            <Grid
              item
              container
              direction='column'
              xs={12}
              sm={12}
              gap={1}
              sx={{
                padding: '20px 20px',
                boxShadow: '0px 0px 20px 5px #cdcdcd4d',
                backgroundColor: '#ffffff3d',
                backdropFilter: 'blur(20px)',
                borderRadius: '10px',
              }}
            >
              <Typography variant='subtitle2'>Operator</Typography>
              <Autocomplete
                id='combo-box-demo'
                size='small'
                disabled={depart?.id !== 0 ? false : true}
                options={!isUser ? users : []}
                // value={assetHrs}
                fullWidth={true}
                loading={isUser}
                getOptionLabel={(option) => option.display_name}
                PaperComponent={CustomPaper}
                onChange={(event, newValue) => {
                  callback((prev) => ({
                    ...prev,
                    [category]: {
                      ...prev[category],
                      Operator: newValue?.id,
                    },
                  }));
                }}
                renderInput={(params) => (
                  <TextField required {...params} size='small' />
                )}
              />
            </Grid>
          )}
          <Grid
            item
            container
            direction='column'
            xs={12}
            sm={12}
            gap={1}
            sx={{
              padding: '20px 20px',
              boxShadow: '0px 0px 20px 5px #cdcdcd4d',
              backgroundColor: '#ffffff3d',
              backdropFilter: 'blur(20px)',
              borderRadius: '10px',
            }}
          >
            <Typography variant='subtitle2'>Document No</Typography>
            <TextField
              fullWidth
              type='text'
              size='small'
              required
              onChange={(event) => {
                callback((prev) => ({
                  ...prev,
                  [category]: {
                    ...prev[category],
                    DocumentNo: event.target.value,
                  },
                }));
              }}
            />
          </Grid>
          <Grid
            item
            container
            direction='column'
            xs={12}
            sm={12}
            gap={1}
            sx={{
              padding: '20px 20px',
              boxShadow: '0px 0px 20px 5px #cdcdcd4d',
              backgroundColor: '#ffffff3d',
              backdropFilter: 'blur(20px)',
              borderRadius: '10px',
            }}
          >
            <Typography variant='subtitle2'>Create At</Typography>
            <TextField
              fullWidth
              type='datetime-local'
              required
              size='small'
              onChange={(event) => {
                callback((prev) => ({
                  ...prev,
                  [category]: {
                    ...prev[category],
                    CreateAt: event.target.value,
                  },
                }));
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
