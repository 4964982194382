import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  // Drawer,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuButtons } from '../../utilities/menuButton';
import EditIcon from '@mui/icons-material/Edit';
import { DialogBox } from '../../utilities/dialog';
import { Add } from '../users/add';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
import { DeleteContent } from '../../../hooks/utils';

const items = [
  {
    label: 'Edit Department',
    icon: <EditIcon fontSize='small' />,
  },
  {
    label: 'Delete Department',
    icon: <DeleteIcon fontSize='small' />,
  },
];

const csvCol = [
  {
    key: 'name',
    label: 'Title',
  },
  {
    key: 'siteName',
    label: 'Site',
  },
  {
    key: 'assets',
    label: 'Assets',
  },

  {
    key: 'createdAt',
    label: 'Created At',
  },
  {
    key: 'updatedAt',
    label: 'Update At',
  },
];
export const DepartmentComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialog, setIsDialog] = React.useState(false);
  const [setMobileOpens, setMobileOpenss] = React.useState(false);
  const [department, setDepartment] = React.useState(null);
  const [filtered, setFiltered] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['Fetch_department_list'],
    queryFn: () => DepartmentServices.allDepartments(),
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Title',
      width: 280,
    },
    {
      field: 'siteName',
      headerName: 'Site',
      width: 220,
    },
    {
      field: 'assets',
      headerName: 'Assets',
      width: 180,
    },

    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 250,
    },
    {
      field: 'updatedAt',
      headerName: 'Update At',
      width: 200,
    },

    {
      field: '',
      headerName: '',
      width: 40,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setMobileOpenss(!setMobileOpens);
  };
  const handleMenuItem = (item, params) => {
    if (item.label === 'Departments') {
      toggleDrawer();
      setAnchorEl(null);
    } else if (item.label === 'Edit Department') {
      // navigate('/inspection/edit');
      setAnchorEl(null);
      setIsDialog(() => true);
    } else if (item.label === 'Delete Department') {
      setAnchorEl(null);
      setIsDelete(true);
      // setSelectedData((prev) => ({
      //   ...prev,
      //   isSelected: true,
      //   rowId: params.id,
      // }));
    } else {
      return null;
    }
  };

  const { mutate } = useMutation({
    mutationFn: DepartmentServices.department,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Fetch_department_list'] });
      setIsDelete(false);
    },
  });

  // console.log(department);
  return (
    <>
      <MainContent
        csvData={data}
        csvHeaders={csvCol}
        csvDataWithFilter={filtered || data}
        csvHeadersWithFilter={csvCol}
        fileNameWithFilter={'Department  list'}
        fileName={'Department  list'}
        valueFrom='add_department'
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setFiltered(() => data);
            return;
          }
          const value = data.filter(
            (item) => item.name.toLowerCase().indexOf(val.target.value) > -1
          );
          setFiltered(() => value);
        }}
        items={
          <TableData
            cols={columns}
            rows={filtered || data}
            maxWidthMob='84vw'
            height='400px'
            isLoading={isLoading}
            onRowClick={({ row }) => setDepartment(() => row)}
          />
        }
        isBtnName={true}
        btnName={'Add'}
        onBtnNameClick={() => {
          setDepartment(null);
          setIsDialog(true);
        }}
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Department
          </Typography>
        }
        content={
          <Add
            label_name='Department'
            handleClose={() => setIsDialog(false)}
            department={department}
            mutationFn={
              department
                ? DepartmentServices.department
                : DepartmentServices.addDepartment
            }
          />
        }
      />
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete Department
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this department?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = department.id;
              const method = 'delete';
              mutate({ id, method });
            }}
          />
        }
      />
    </>
  );
};
