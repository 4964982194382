/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, Box, Divider, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  // Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
} from 'recharts';
import { logo } from '../../../constant/icons';

export const ReportPrinter = React.forwardRef(
  (
    { data, date, finalData, assetData, promiseResolveRef, isPrinting },
    ref
  ) => {
    const today = new Date().toLocaleDateString();
    const [user, setUser] = React.useState('');

    const getUser = () => {
      const { authenticated } = JSON.parse(sessionStorage.getItem('user'));
      setUser(authenticated.displayName);
    };

    useEffect(() => {
      getUser();
      if (isPrinting && promiseResolveRef.current) {
        // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
        promiseResolveRef.current();
      }
    }, []);

    return (
      <div ref={ref}>
        <Grid
          container
          direction='column'
          gap={3}
          sx={{
            padding: '20px 40px 40px',
          }}
        >
          <Grid item container justifyContent={'center'}>
            <Box
              src={logo}
              component='img'
              sx={{
                width: '20%',
                height: '60px',
                marginTop: '40px',
              }}
              alt='InspectFleet'
            />
          </Grid>
          {/* <Grid item>
            <Box
              src={shortLogo}
              component='img'
              sx={{
                width: '5%',
                height: '40px',
              }}
              alt='short logo'
            />
          </Grid> */}

          <Grid item container direction='column' gap={3}>
            <Grid item>
              <Typography variant='h5' color='primary'>
                Prestart report 0f {date}
              </Typography>
            </Grid>
            {data && (
              <Grid item container>
                <Grid item xs={12} sm={10}>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      data={data}
                      margin={{
                        top: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='name'
                        angle={10}
                        textAnchor='start'
                        interval={0}
                        dy={0}
                      />
                      <YAxis dataKey='height' />

                      {/* <Legend /> */}
                      <Bar
                        dataKey='total'
                        stackId='a'
                        fill='#2D76AB'
                        // isAnimationActive={false}
                        // activeBar={<Rectangle fill='pink' stroke='blue' />}
                      >
                        <LabelList
                          dataKey='total'
                          position='top'
                          angle={0}
                          offset={25}
                          fill='red'
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <ResponsiveContainer height={300}>
                    <BarChart
                      data={finalData}
                      margin={{
                        top: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis dataKey='height' />
                      {/* <Legend /> */}
                      <Bar
                        dataKey='total'
                        stackId='a'
                        // label={<Typography>hh</Typography>}
                        fill='#2D76AB'
                        // activeBar={<Rectangle fill='pink' stroke='blue' />}
                      >
                        <LabelList
                          dataKey='total'
                          position='top'
                          angle={0}
                          offset={25}
                          fill='red'
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}

            {assetData.length > 0 && (
              <Grid
                item
                xs={12}
                sm={12}
                container
                direction='column'
                gap={2}
                paddingTop={'40px'}
              >
                <Grid item>
                  <Typography variant='h5'>
                    Prestart by Department of {date}
                  </Typography>
                </Grid>
                <Grid item>
                  <ResponsiveContainer height={400}>
                    <BarChart
                      data={assetData?.map((item) => ({
                        assetId: item.assetId,
                        target_met_percentage: item.target_met_percentage,
                        total: 100,
                      }))}
                      margin={{
                        top: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='assetId'
                        angle={10}
                        textAnchor='start'
                        interval={0}
                        dy={0}
                      />
                      <YAxis dataKey='total' />
                      {/* <Tooltip /> */}
                      {/* <Legend /> */}
                      <Bar
                        dataKey='target_met_percentage'
                        stackId='a'
                        // label={<Typography>hh</Typography>}
                        fill='#2D76AB'
                      >
                        <LabelList
                          dataKey='target_met_percentage'
                          position='top'
                          angle={0}
                          offset={25}
                          fill='red'
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              direction='column'
              gap={1}
              sx={{
                paddingInline: '10px',
              }}
            >
              <Grid item>
                <Typography variant='body1' fontWeight={400} color='primary'>
                  Prepared by
                </Typography>
              </Grid>
              <Grid item container direction='row' spacing={3}>
                <Grid item>
                  <Grid container direction='column'>
                    <Typography variant='body2' color='primary'>
                      {user}
                    </Typography>
                    <Typography variant='body2' color='primary'>
                      {today}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ paddingTop: 2 }} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);
