import React, { useEffect } from 'react';
import { UrlsRoute } from './common/routes/urls';
import { Navigate } from 'react-router-dom';

const App = () => {
  const user = sessionStorage?.getItem('user') ?? null;
  const [auth, setAuth] = React.useState(null);
  const getAuth = async () => {
    const { authenticated } =
      (await JSON.parse(sessionStorage.getItem('user'))) ?? {};

    const permissions =
      authenticated?.group?.map((item) => item?.permissions) ?? [];
    const perms = permissions?.map(
      (item) => item?.map((item) => item?.codename) ?? []
    );
    setAuth(perms);
  };

  useEffect(() => {
    getAuth();
  }, []);

  if (!user) {
    return <Navigate to='/login' />;
  }
  return (
    <div>
      <UrlsRoute auth={auth} />
    </div>
  );
};

export default App;
