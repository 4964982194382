import React, { useEffect } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

const StyledList = styled(List)({
  '& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#2d77ab',
    transform: 'scale(1)',
    '& .MuiTypography-root': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  '& .MuiListItemButton-root.Mui-selected': {
    backgroundColor: '#2d77ab',
    borderRadius: '0 10px 10px 0',
  },
  '& .MuiListItemButton-root': {
    transition: 'all 200ms cubic-bezier(1, 0.12, 1, 1.03)',
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
    transform: 'scale(1.06)',
    '& .MuiTypography-root': {
      color: '#2D77AB',
    },
    '& .MuiListItemIcon-root': {
      color: '#2D77AB',
    },
  },
});

export const DrawerList = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(null);

  const { pathname } = useLocation();
  const route = pathname.toString().match(/\/([\w\d]*)/g)[0];
  const getAuth = () => {
    const { authenticated } = JSON.parse(sessionStorage.getItem('user')) ?? {};

    const permissions =
      authenticated?.group?.map((item) => item.permissions) ?? {};
    const perms = permissions?.map((item) =>
      item?.map((item) => item.codename)
    );
    setAuth(perms);
  };
  useEffect(() => {
    getAuth();
  }, []);
  return auth?.map((perm) =>
    [
      {
        id: 1,
        name: 'Dashboard',
        icon: (
          <HomeOutlinedIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/',
      },
      // {
      //   id: 2,
      //   name: 'Reports',
      //   icon: (
      //     <ReportIcon
      //       sx={{
      //         fontSize: '25px',
      //       }}
      //     />
      //   ),
      //   path: '/reports',
      // },
      perm?.includes('view_inspection') && {
        id: 3,
        name: 'Prestart',
        icon: (
          <EventNoteOutlinedIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/prestart',
      },
      perm?.includes('view_asset') && {
        id: 4,
        name: 'Assets',
        icon: (
          <WebAssetIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/assets',
      },
      perm?.includes('view_asset') && {
        id: 5,
        name: 'Service Scheduling',
        icon: (
          <CarRepairIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/services',
      },
      perm?.includes('view_issue') && {
        id: 6,
        name: 'Issues',
        icon: (
          <LayersOutlinedIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/issue',
      },

      perm?.includes('view_user') && {
        id: 7,
        name: 'User Management',
        icon: (
          <PersonIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/users',
      },
      perm?.includes('view_user') && {
        id: 8,
        name: 'Company',
        icon: (
          <ApartmentIcon
            sx={{
              fontSize: '25px',
            }}
          />
        ),
        path: '/company',
      },
    ].map((item) => (
      <StyledList key={item.id}>
        <ListItemButton
          color='primary'
          selected={route === item.path}
          onClick={() => {
            navigate(item.path);
            // setSelectedIndex(item.id);
          }}
          disableTouchRipple
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant='body1'
                color={route === item.path ? 'primary.light' : 'primary'}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItemButton>
      </StyledList>
    ))
  );
};
