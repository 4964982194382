import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import DepartmentServices from '../../../service/department';
import { useQuery } from '@tanstack/react-query';
import CustomPaper from '../../utilities/customPaper';
import AssetService from '../../../service/asset';

const items = [
  {
    labels: 'start_date',
    placeholder: 'start_date',
    required: true,
    form: 'start_date',
    type: 'date',
  },
  {
    labels: 'end_date',
    placeholder: 'end_date',
    required: true,
    form: 'end_date',
    type: 'date',
  },
];

export const AssetFilterAverage = ({ assetFilters, filterAssetData }) => {
  const { handleSubmit, register } = useForm();
  const [depart, setDepart] = React.useState(null);
  const [asset, setAsset] = React.useState(null);

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_asset'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      return resp;
    },
  });

  const { data: assetDepartment, isLoading: isLoadingAssetDepartment } =
    useQuery({
      queryKey: ['Fetch_sub_assets', depart?.id],
      queryFn: depart
        ? async () => {
            const id = depart?.id;
            const resp = await AssetService.assetByDepartment({
              department: id,
            });
            return resp;
          }
        : [],
    });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const { start_date, end_date } = data;
    assetFilters({
      assetId: asset?.id,
      start_date: start_date,
      end_date: end_date,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={5} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Department</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            PaperComponent={CustomPaper}
            options={!isDepartment ? departments : []}
            value={depart}
            loading={isDepartment}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setAsset(null);
              setDepart(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size='small' required />
            )}
          />
        </Grid>
        {depart?.id > 0 && (
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>AssetID</Typography>
            <Autocomplete
              fullWidth={true}
              disabled={depart?.id !== 0 ? false : true}
              size='small'
              id='combo-box-demo'
              PaperComponent={CustomPaper}
              options={!isLoadingAssetDepartment ? assetDepartment : []}
              value={asset}
              loading={isLoadingAssetDepartment}
              getOptionLabel={(option) => option.assetId}
              onChange={(event, newValue) => {
                setAsset(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' required />
              )}
            />
          </Grid>
        )}
        {items.map((item) => (
          <Grid item xs={12} sm={3} key={item.labels}>
            <Grid container direction='column'>
              <Typography variant='body1' size='medium'>
                {item.labels}
              </Typography>

              {
                <TextField
                  size='small'
                  {...item}
                  required={true}
                  fullWidth={true}
                  {...register(`${item.form}`)}
                />
              }
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} container direction='column' gap={2}>
          <Grid item>
            <Typography variant='h6'>Filtering results</Typography>
          </Grid>
          <Grid item container direction={'column'} gap={1}>
            <Grid item>
              <Typography variant='subtitle2'>
                Total: {filterAssetData?.total}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                Average: {filterAssetData?.average}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
