import React, { useEffect, useState } from 'react';
import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Avatar,
  // Button,
  IconButton,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import LoadingButton from '@mui/lab/LoadingButton';
import { useQuery, useMutation } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import CloseIcon from '@mui/icons-material/Close';
import InspectionServices from '../../../service/inspection';
import { useForm } from 'react-hook-form';
import CustomPaper from '../../utilities/customPaper';
// import { Delete } from '@mui/icons-material';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export const ShareInspect = ({ toggleDrawer, inspection }) => {
  // handle pdf
  // const [pdfData, setPdfData] = React.useState(null);

  // const handlePdf = (e) => {
  //   const value = e.target.files[0];
  //   setPdfData(value);
  // };

  //
  const [user, setUser] = useState([]);
  const [userInfo, setUserInfo] = useState({
    id: null,
    email: null,
    fullname: null,
  });

  const { handleSubmit, register, reset } = useForm({});
  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: () => UserServices.getAllUser(),
  });

  console.log(users);
  const { mutate, isPending } = useMutation({
    mutationFn: InspectionServices.inspectionShare,
    onSuccess: () => {
      toggleDrawer();
      reset();
      setUser([]);
      // setPdfData(null);
    },
  });

  const onSubmit = (data, e) => {
    const { inspectionId, asset } = inspection;
    e.preventDefault();
    const { description } = data;
    const email = userInfo.email;

    // if (pdfData !== null) {
    //   mutate({
    //     inspectionId,
    //     vehicle_model,
    //     description,
    //     email,
    //     user,
    //     pdfData,
    //   });
    // } else {
    mutate({
      inspectionId,
      asset,
      description,
      email,
      user,
    });
    // }
  };

  const getUser = () => {
    const { authenticated } = JSON.parse(sessionStorage.getItem('user')) ?? {};

    setUserInfo({
      id: authenticated.id,
      fullname: authenticated.displayName,
      email: authenticated.email,
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  // console.log(pdfData);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' gap={4}>
        <Grid item container direction='row' spacing={1}>
          <Grid item xs={8} sm={8}>
            <Autocomplete
              size='small'
              disablePortal
              value={null}
              blurOnSelect={true}
              id='combo-box-demo'
              options={users}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.display_name}
              renderInput={(params) => (
                <TextField fullWidth size='small' {...params} />
              )}
              onChange={(event, newValue) => {
                setUser((prev) => [...prev, newValue]);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <LoadingButton
              loading={isPending}
              disabled={user.length === 0 ? true : false}
              variant='contained'
              startIcon={<ShareIcon />}
              type='submit'
            >
              Share
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body1'>Inspection available to: </Typography>
        </Grid>
        <Grid item container direction='column'>
          <Grid item container alignItems='center' gap={1}>
            {user.length > 0 ? (
              user.map((item, index) => (
                <Grid item container gap={1} key={index} alignItems='center'>
                  <Avatar sx={{ width: 20, height: 20 }} />
                  <Typography variant='subtitle2'>{item.email}</Typography>
                  <IconButton
                    fontSize={10}
                    onClick={() => {
                      setUser((prev) =>
                        prev.filter((val, i) => {
                          return i !== index;
                        })
                      );
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              ))
            ) : (
              <Typography variant='caption'>Add email here ...</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container direction={'column'} gap={1}>
          <Typography>Write your Email body</Typography>
          <TextField
            placeholder='write ....'
            multiline
            rows={3}
            fullWidth
            variant='filled'
            required={true}
            {...register('description')}
          />
        </Grid>
        {/* <Grid item container direction='column' gap={1}>
          <Grid item>
            <Typography variant='body1'>Upload Pdf</Typography>
          </Grid>
          {pdfData === null ? (
            <Grid item>
              <input
                type='file'
                id='select-file'
                onChange={handlePdf}
                style={{ display: 'none' }}
              />
              <label htmlFor='select-file'>
                <Button
                  variant='outlined'
                  color='primary'
                  component='span'
                  sx={{
                    padding: '5px 30px',
                  }}
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  PDF
                </Button>
              </label>
            </Grid>
          ) : (
            <Grid item container alignItems='center'>
              <Typography variant='body2' color='primary.light'>
                {pdfData?.name}
              </Typography>
              <IconButton
                sx={{
                  color: '#f95e5e',
                }}
                onClick={() => setPdfData(null)}
              >
                <Delete sx={{ fontSize: '15px' }} />
              </IconButton>
            </Grid>
          )}
        </Grid> */}
      </Grid>
    </form>
  );
};
