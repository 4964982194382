import React from 'react';
import {
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ShareIcon from '@mui/icons-material/Share';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import InspectionServices from '../../../service/inspection';
import { ReportOverview } from './reportOverview';
import { ReportAudit } from './reportAudit';
import { useReactToPrint } from 'react-to-print';
import { InspectionPrint } from './inspectionPrint';
import { DialogBox } from '../../utilities/dialog';
import { ShareInspect } from './share';
import { AddIssue } from '../issue/addIssue';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

export const ReportView = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isDialog, setIsDialog] = React.useState(false);

  const [setMobileOpens, setMobileOpenssetMobileOpens] = React.useState(false);

  const { id } = useParams();

  const { data: inspection } = useQuery({
    queryKey: ['report_inspection', id],
    queryFn: async () => await InspectionServices.getInspectionById(id),
  });

  /// print a receipt
  // handle print
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: inspection && inspection.inspectionId,
    onAfterPrint: () => {
      // dispatch(AddedRemove());
    },
  });
  const toggleDrawer = () => {
    setMobileOpenssetMobileOpens(!setMobileOpens);
  };

  return (
    <Grid
      container
      direction='column'
      sx={{
        padding: { sm: '20px 60px' },
      }}
      gap={3}
    >
      <Grid item container direction='row' spacing={2}>
        <Grid item xs={12} sm={8}>
          <Grid container direction='row' gap={1} alignItems='center'>
            <IconButton onClick={() => navigate('/prestart')}>
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>
            {inspection && (
              <Typography variant='h6'>
                {inspection.inspectionId} - {inspection.user_fullname}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container justifyContent='flex-end' xs={12} sm={4} gap={1}>
          <Grid item>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              startIcon={<LayersOutlinedIcon />}
              onClick={() => setIsDialog(true)}
            >
              Actions
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              startIcon={<FileUploadOutlinedIcon />}
              onClick={handlePrint}
            >
              PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              startIcon={<ShareIcon />}
              onClick={() => setOpen(!open)}
            >
              Share
            </Button>
            <DialogBox
              handleClose={() => setOpen(false)}
              open={open}
              title={
                <Typography variant='subtitle1'>
                  Share a comment with vehicleNo
                  {inspection?.asset}
                </Typography>
              }
              content={
                <ShareInspect
                  toggleDrawer={toggleDrawer}
                  inspection={inspection && inspection}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        sx={{ padding: '0px 0px 10px 0px' }}
        spacing={2}
      >
        <Grid item xs={12} sm={9}>
          <Grid container direction='column' gap={4}>
            {inspection && <ReportOverview inspection={inspection} />}
            {inspection && <ReportAudit inspection={inspection} />}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            direction='column'
            sx={{
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
              padding: '20px 20px',
            }}
          >
            <Grid item>
              <Typography variant='body1' color='primary.light'>
                Overview
              </Typography>
            </Grid>
            <Grid item>
              <List
                subheader={
                  <ListSubheader
                    sx={{
                      // '&:MuiListSubheader-root': {
                      backgroundColor: 'transparent',
                      paddingLeft: '0px',
                      // },
                    }}
                  >
                    Audit
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemText primary='Category A' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Category B' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Category C' />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {inspection && (
        <Grid item sx={{ display: 'none' }}>
          <InspectionPrint ref={componentRef} inspection={inspection} />
        </Grid>
      )}
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Report Issue -<small>{inspection && inspection.inspectionId}</small>
          </Typography>
        }
        content={
          <AddIssue
            handleClose={() => setIsDialog(false)}
            inspection={inspection}
            // issue={issue}
          />
        }
      />
    </Grid>
  );
};
