import React from 'react';
import { CheckList } from './checklist';

import { itemsA, itemsB, itemsC } from '../../../utilities/question';
export const VehicleCheckList = () => {
  const asset = {
    'Category A': [],
    'Category B': [],
    'Category C': [],
    'Extra details': [],
  };

  return (
    <CheckList
      form='vehicle'
      itemsA={itemsA}
      itemsB={itemsB}
      itemsC={itemsC}
      asset={asset}
    />
  );
};
