import React from 'react';
import { Grid, Typography, Button, Avatar, Stack, Chip } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { BasicTabView } from '../../utilities/tab';
import { Settings } from './settings';
import { Author } from './auth';
import { useQuery } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import { Overview } from './overview';

export const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: user,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['user', id],
    queryFn: () => UserServices.getUserById(id),
  });

  const tab = [
    {
      id: 1,
      tabTitle: 'Info',
      tabContent: (
        <Settings
          user={user !== undefined && user}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'Authorizations',
      tabContent: (
        <Author
          user={user}
          isLoading={isLoading}
          error={error}
          isError={isError}
        />
      ),
    },
  ];
  return (
    <Grid container direction='column' gap={5}>
      <Grid item>
        <Button startIcon={<WestIcon />} onClick={() => navigate('/users')}>
          back
        </Button>
      </Grid>

      <Grid item container direction='row' spacing={2} alignItems='stretch'>
        <Grid item xs={12} sm={2} container direction='column' gap={2}>
          <Grid item>
            <Avatar
              variant='rounded'
              sx={{ width: 140, height: 140, backgroundColor: '#7c8d9f' }}
            />
          </Grid>
          <Grid item container direction='column' gap={1}>
            <Typography variant='subtitle1' fontWeight={400}>
              {isLoading ? '--' : user?.display_name}
            </Typography>
            <Typography
              variant='subtitle2'
              color='secondary.light'
              fontWeight={400}
            >
              {isLoading ? '--' : user?.email}
            </Typography>
            <Stack>
              <Chip label={isLoading ? '--' : user?.idnumber} />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Grid
            container
            sx={{
              marginTop: '40px',
              padding: '20px 20px',
              backgroundColor: '#2d77ab1a',
              display: {
                xs: 'none',
                md: 'block',
              },
              borderRadius: '10px',
            }}
          >
            <Overview user={user} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
