import React from 'react';
import { PropTypes } from 'prop-types';
import { BasicTabView } from '../../utilities/tab';
import { GroupComponent } from '../../components/users/group';
import { UserComponent } from '../../components/users';

const tab = [
  {
    id: 1,
    tabTitle: 'Users',
    tabContent: <UserComponent />,
  },

  {
    id: 2,
    tabTitle: 'Role',
    tabContent: <GroupComponent />,
  },
];
export const Users = () => {
  return <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />;
};

Users.propTypes = {
  tab: PropTypes.array,
};
