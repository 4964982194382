import { Button, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { ExtraDetails } from '../../../utilities/extraDetails';

const answers = [
  {
    id: '001',
    label: 'Yes',
    value: 'yes',
    bgcolor: '#ADAD0F',
  },
  {
    id: '002',
    label: 'No',
    value: 'no',
    bgcolor: '#2D77AB',
  },
  {
    id: '003',
    label: 'N/A',
    value: 'n/a',
    bgcolor: '#032842',
  },
];
export const QuestionView = ({ item, callback, category }) => {
  const { icon, label, subLabel } = item;
  const [selectedId, setSelectedId] = React.useState({
    id: 0,
    itemId: '',
  });
  const [question, setQuestion] = React.useState(false);

  const renderItem = ({ id, label, value, bgcolor }, index) => {
    return (
      <Button
        key={index}
        fullWidth
        onClick={() => {
          setSelectedId({
            id: id,
            itemId: item?.id,
          });
          callback((prev) => ({
            ...prev,
            [category]: [...prev[category], { [item.label]: value }],
          }));
          setQuestion(true);
        }}
        sx={{
          minWidth: '150px',
          backgroundColor:
            selectedId.id === id && selectedId.itemId === item?.id
              ? bgcolor
              : 'white',
          color:
            selectedId.id === id && selectedId.itemId === item?.id
              ? 'white'
              : 'primary.main',
        }}
        variant={
          selectedId.id === id && selectedId.itemId === item?.id
            ? 'text'
            : 'outlined'
        }
      >
        {label}
      </Button>
    );
  };

  return (
    <Grid
      container
      direction='column'
      gap={5}
      sx={{
        padding: '20px 20px',
        boxShadow: '0px 0px 20px 5px #cdcdcd4d',
        backgroundColor: '#ffffff3d',
        backdropFilter: 'blur(20px)',
        borderRadius: '10px',
      }}
    >
      <Grid item>
        <Grid container direction='row' spacing={2} alignItems='center'>
          {icon && (
            <Grid item>
              <CardMedia
                src={icon}
                component='img'
                sx={{
                  height: 20,
                }}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container direction='column'>
              <Typography>{label}</Typography>
              <Typography variant='caption'>{subLabel}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction='row' gap={2}>
        {answers.map((answer, index) => renderItem(answer, index))}
      </Grid>
      <Grid item>
        <ExtraDetails
          callback={callback}
          category={category}
          label={label}
          question={question}
          callQuestion={setQuestion}
        />
      </Grid>
    </Grid>
  );
};
