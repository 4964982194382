import { Button, Grid, IconButton, Typography } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

export const FormNav = ({ handleCategory, cat, score }) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        padding: '20px 20px',
        boxShadow: '0px 0px 20px 5px #cdcdcd4d',
        backgroundColor: '#ffffff3d',
        backdropFilter: 'blur(20px)',
        borderRadius: '10px',
      }}
    >
      <Grid item>
        <Grid
          container
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='center'
        >
          {cat > 0 ? (
            <Button
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => handleCategory('left', cat)}
              startIcon={<KeyboardBackspaceOutlinedIcon />}
            >
              Back
            </Button>
          ) : (
            <Button
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => navigate('/prestart')}
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <Typography variant='subtitle2' fontWeight={400}>
              Audit &nbsp;
              <Typography
                variant='caption'
                component='span'
                color='primary.light'
              >
                {score}%
              </Typography>
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
        }}
      >
        <Grid container direction='row' gap={1} alignItems='center'>
          <IconButton onClick={() => navigate('/prestart')}>
            <MoreHorizIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
