export const vehicleInstructions = [
  {
    label: '1. Category A',
    desc: 'Faults is a No Go, the maintenance team must be informed immediately.',
  },
  {
    label: '2. Category B',
    desc: ' Faults must be brought to the workshop to repair immediately',
  },
  {
    label: '3. Category C',
    desc: 'Faults must be rectified by the end-user',
  },
];

export const machineInstructions = [
  {
    label: '1. V ',
    desc: 'V = Ok',
  },
  {
    label: '2. X',
    desc: 'X = Fault / Not Done',
  },
  {
    label: '3. NA',
    desc: 'Not applicable',
  },
];
