import fit from '../../assets/icons/fit.png';
import oil from '../../assets/icons/oil.png';
import cooling from '../../assets/icons/cooling.png';
import brake_fluid from '../../assets/icons/brake_fluid.png';
import steering from '../../assets/icons/steering.png';
import leaks from '../../assets/icons/Daco_4828687.png';
import v_belt from '../../assets/icons/v_belt.png';
import battery from '../../assets/icons/battery.png';
import warning from '../../assets/icons/warning.png';
import parking_brake from '../../assets/icons/parking_brake.png';
import pedal_brake from '../../assets/icons/pedal_brake.png';
// category b
import windowField from '../../assets/icons/windshield.png';
import wiper from '../../assets/icons/wiper.png';
import rearmirror from '../../assets/icons/rearmirror.png';
import carlight from '../../assets/icons/carlight.png';
import indicators from '../../assets/icons/indicators.png';
import tyrestate from '../../assets/icons/tyrestate.png';
import rim from '../../assets/icons/rim.png';
import wheelshock from '../../assets/icons/wheelshock.png';
import flags from '../../assets/icons/flag.png';
import seatbelt from '../../assets/icons/seatbelt.png';
import seats from '../../assets/icons/seats.png';
import alarmSound from '../../assets/icons/alarmSound.png';
import horn from '../../assets/icons/horn.png';
import flashLight from '../../assets/icons/flashLight.png';
import radio from '../../assets/icons/radio.png';
import bodyDamaged from '../../assets/icons/bodyDamaged.png';
// category c
import windowWasher from '../../assets/icons/windowWasher.png';
import license from '../../assets/icons/license.png';
import fuel from '../../assets/icons/fuel.png';
import fire_extinguisher from '../../assets/icons/fire_extinguisher.png';
import first_kit_aid from '../../assets/icons/first_kit_aid.png';
import safety_triangle from '../../assets/icons/safety_triangle.png';
import id from '../../assets/icons/id.png';
import car_clean from '../../assets/icons/car_clean.png';

export const itemsA = [
  {
    id: 'A1',
    label: 'Are you fit to operate the vehicle',
    subLabel: 'Etes-vous apte a conduire ce vehicule',
    icon: fit,
  },
  {
    id: 'A2',
    label: 'Check engine oil level',
    subLabel: "Vérifier le niveau d'huile moteur",
    icon: oil,
  },
  {
    id: 'A3',
    label: 'Check radiator level',
    subLabel: 'Vérifier le niveau de refroidisseur du radiateur,',
    icon: cooling,
  },
  {
    id: 'A4',
    label: 'Check brake fluid level',
    subLabel: 'Vérifier le niveau du liquide de frein',
    icon: brake_fluid,
  },
  {
    id: 'A5',
    label: 'Check steering fluid level',
    subLabel: "Vérifier le niveau d'huile de direction",
    icon: steering,
  },
  {
    id: 'A6',
    label: 'Check for any leaks',
    subLabel: 'Vérifier toutes fuites',
    icon: leaks,
  },
  {
    id: 'A7',
    label: 'Check all V-belt for damage',
    subLabel: 'Vérifier toutes les courroies pour les dommage',
    icon: v_belt,
  },
  {
    id: 'A8',
    label: 'Inspect battery & clamps',
    subLabel: 'Inspecter la batterie et les anneaux de fixations',
    icon: battery,
  },
  {
    id: 'A9',
    label: 'Check for warning signs on cluster',
    subLabel: "Vérifier tout signal d'avertissement sur le secteur",
    icon: warning,
  },
  {
    id: 'A10',
    label: 'Ensure park brake is working',
    subLabel: 'Se rassurer que le frein de stationnement fonctionne',
    icon: parking_brake,
  },
  {
    id: 'A11',
    label: 'Ensure service brake is working',
    subLabel: 'Se rassurer que le frein de service fonctionne',
    icon: pedal_brake,
  },
];

export const itemsB = [
  {
    id: 'B1',
    label: 'Check all the windows for damage',
    subLabel: 'Vérifier les fenêtres pour tout dommage',
    icon: windowField,
  },
  {
    id: 'B2',
    label: 'Check wipers condition',
    subLabel: "Vérifer l'état des essuie-glaces",
    icon: wiper,
  },
  {
    id: 'B3',
    label: 'Check all mirrors for damage',
    subLabel: 'Vérifer tous les retroviseurs pour tout dommage',
    icon: rearmirror,
  },
  {
    id: 'B4',
    label: 'Ensure all lights are working',
    subLabel: 'Se rassurer que toutes les lumières fonctionnent',
    icon: carlight,
  },
  {
    id: 'B5',
    label: 'Ensure all indicators are working',
    subLabel: 'Se rassurer que tous les clignotants fonctionnent',
    icon: indicators,
  },
  {
    id: 'B6',
    label: 'Check tires condition',
    subLabel: "Vérifer l'ètat des pneus",
    icon: tyrestate,
  },
  {
    id: 'B7',
    label: 'Inspect wheel nuts',
    subLabel: 'Inspecter les écrous de roue',
    icon: rim,
  },
  {
    id: 'B8',
    label: 'Check wheel nut indicators',
    subLabel: 'Vérifier les indicateurs des écrous de roue',
    icon: wheelshock,
  },
  {
    id: 'B9',
    label: 'Check flag pole',
    subLabel: 'Vérifer le mât du drapelet',
    icon: flags,
  },
  {
    id: 'B10',
    label: 'Check all seat belts for damage',
    subLabel:
      'Vérifier toutes les ceintures de sécurité pour pour tout dommage',
    icon: seatbelt,
  },
  {
    id: 'B11',
    label: 'Check all seats for damage',
    subLabel: 'Vérifier tous les sieges pour tout dommage',
    icon: seats,
  },
  {
    id: 'B12',
    label: 'Ensure the horn is working',
    subLabel: 'Se rassurer que le kiaxon fonctionne',
    icon: horn,
  },
  {
    id: 'B13',
    label: 'Ensure reverse alarm is working',
    subLabel: "Se rassurer que l'alarme de marche arrière fonctionne",
    icon: alarmSound,
  },
  {
    id: 'B14',
    label: 'Ensure flashing beacons is working',
    subLabel: 'Se rassurer que le girophare fonctionne',
    icon: flashLight,
  },
  {
    id: 'B15',
    label: 'Check radio (Surface & UG mining)',
    subLabel:
      'Vérifier la radio de communication (surface et mine souterraine)',
    icon: radio,
  },
  {
    id: 'B16',
    label: 'Record body damage',
    subLabel: 'Rapporter tout dommage sur la carroserie',
    icon: bodyDamaged,
  },
];

export const itemsC = [
  {
    id: 'C1',
    label: 'Ensure the window washer fluid is full',
    subLabel: 'Se rassurer que le liquide lave-glace est plein',
    icon: windowWasher,
  },
  {
    id: 'C2',
    label: 'Ensure the licenses & permit are valid ',
    subLabel: 'Se rassurer que le permis de conduire  sont valides',
    icon: license,
  },
  {
    id: 'C3',
    label: 'Ensure fuel tank  is full',
    subLabel: 'Se rassurer que le reservoir de carburant est plein',
    icon: fuel,
  },
  {
    id: 'C4',
    label: 'Inspect fire extinguisher',
    subLabel: "Inspect l'extincteur",
    icon: fire_extinguisher,
  },
  {
    id: 'C5',
    label: 'Check wheel chocks',
    subLabel: 'Vérifier les cales de roue',
    icon: wheelshock,
  },
  {
    id: 'C6',
    label: 'Check first aid kit ',
    subLabel: 'Vérifier la trousse de premiers soins',
    icon: first_kit_aid,
  },
  {
    id: 'C7',
    label: 'Check safety triangles',
    subLabel: 'Vérifier les triangles de sécurité',
    icon: safety_triangle,
  },
  {
    id: 'C8',
    label: 'Ensure vehicle ID is clear',
    subLabel:
      "Se rassurer que le numéro d'identification du  véhicle est visible",
    icon: id,
  },
  {
    id: 'C9',
    label: 'Ensure the vehicle is clean',
    subLabel: 'Se rassurer que le véhicle est propre',
    icon: car_clean,
  },
];

export const extraDets = [
  {
    label: 'Mileage',
    placeholder: 'Enter mileage',
  },
  {
    label: 'Comments',
    placeholder: 'Enter comments',
    multiline: true,
    numberOfLines: 3,
  },
];

export const machineItems = [
  {
    id: 'A1',
    label: 'O/S TAGS',
    // subLabel: 'Etes-vous apte a conduire ce vehicule',
  },
  {
    id: 'A2',
    label: 'Engine Oil',
    // subLabel: "Vérifier le niveau d'huile moteur",
  },
  {
    id: 'A3',
    label: 'Engine coolant',
    // subLabel: 'Vérifier le niveau de refroidisseur du radiateur,',
  },
  {
    id: 'A4',
    label: 'Transmission Oil',
    // subLabel: 'Vérifier le niveau du liquide de frein',
  },
  {
    id: 'A5',
    label: 'Brake Fluid',
    // subLabel: "Vérifier le niveau d'huile de direction",
  },
  {
    id: 'A6',
    label: 'Hydraulic Oil',
    // subLabel: 'Vérifier toutes fuites',
  },
  {
    id: 'A7',
    label: 'Hydraulic Movements',
    // subLabel: 'Vérifier toutes les courroies pour les dommage',
  },
  {
    id: 'A8',
    label: 'Steering Fluid',
    // subLabel: 'Inspecter la batterie et les anneaux de fixations',
  },
  {
    id: 'A9',
    label: 'Fuel',
    // subLabel: "Vérifier tout signal d'avertissement sur le secteur",
  },
  {
    id: 'A10',
    label: 'V belts',
    // subLabel: 'Se rassurer que le frein de stationnement fonctionne',
  },
  {
    id: 'A11',
    label: 'Oil/Fuel/Coolant leaks',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A12',
    label: 'Engine Bay',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A13',
    label: 'Exhaust',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A14',
    label: 'Greasing',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A15',
    label: 'Types/Rims/jack',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A16',
    label: 'Fire Extinguisher/System Charge',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A17',
    label: 'Clean',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A18',
    label: 'Are you ticketed to operate this equipment',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A19',
    label: 'AFFF / System charge',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A20',
    label: 'Lights/Beacon/Horns/Reverse Alarm',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A21',
    label: 'Gauges/ warning lights',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A22',
    label: 'Seats/ Seats Belts',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A23',
    label: 'Brakes',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A24',
    label: 'Steering',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A25',
    label: 'Window/wipers/Sprays',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A26',
    label: 'Communications',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A27',
    label: 'Cab',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A28',
    label: 'Drifters/Booms',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A29',
    label: 'Air Receivers',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A30',
    label: 'Kettles',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A31',
    label: 'Work baskets/ Attachments (inc. Locking pins)',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A32',
    label: 'Remote control (Checklist)',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
  {
    id: 'A33',
    label: 'Batteries / Cables',
    // subLabel: 'Se rassurer que le frein de service fonctionne',
  },
];

export const extraVehicleForm = [
  {
    title: 'Mileage',
    placeholder: 'Enter mileage',
    required: true,
  },
  {
    title: 'Comments',
    placeholder: 'Enter comments',
    multiline: true,
    rows: 3,
  },
];
export const extraMachineForm = [
  {
    title: 'Hours',
    placeholder: 'Enter hours',
    required: true,
  },
  {
    title: 'Compressor',
    placeholder: 'Enter compressor',
  },
  {
    title: 'Comments',
    placeholder: 'Enter comments',
    multiline: true,
    rows: 3,
  },
];

export const itemsFolkA = [
  {
    id: 'A1',
    label: 'Are you fit to operate the vehicle',
    subLabel: 'Etes-vous apte a conduire ce vehicule',
    icon: fit,
  },
  {
    id: 'A2',
    label: 'Check engine oil level',
    subLabel: "Vérifier le niveau d'huile moteur",
    icon: oil,
  },
  {
    id: 'A3',
    label: 'Check radiator level',
    subLabel: 'Vérifier le niveau de refroidisseur du radiateur,',
    icon: cooling,
  },
  {
    id: 'A4',
    label: 'Check brake fluid level',
    subLabel: 'Vérifier le niveau du liquide de frein',
    icon: brake_fluid,
  },
  {
    id: 'A5',
    label: 'Check steering fluid level',
    subLabel: "Vérifier le niveau d'huile de direction",
    icon: steering,
  },
  {
    id: 'A6',
    label: 'Check for any leaks',
    subLabel: 'Vérifier toutes fuites',
    icon: leaks,
  },
  {
    id: 'A7',
    label: 'Check all V-belt for damage',
    subLabel: 'Vérifier toutes les courroies pour les dommage',
    icon: v_belt,
  },
  {
    id: 'A8',
    label: 'Inspect battery & clamps',
    subLabel: 'Inspecter la batterie et les anneaux de fixations',
    icon: battery,
  },
  {
    id: 'A9',
    label: 'Check for warning signs on cluster',
    subLabel: "Vérifier tout signal d'avertissement sur le secteur",
    icon: warning,
  },
  {
    id: 'A10',
    label: 'Ensure park brake is working',
    subLabel: 'Se rassurer que le frein de stationnement fonctionne',
    icon: parking_brake,
  },
  {
    id: 'A11',
    label: 'Ensure service brake is working',
    subLabel: 'Se rassurer que le frein de service fonctionne',
    icon: pedal_brake,
  },

  {
    id: 'A12',
    label: 'Check if load chart is available',
    subLabel: 'Vérifier si la grille des charges est disponible',
    //  icon: pedal_brake,
  },
  {
    id: 'A13',
    label: 'Inspect boom for defects',
    subLabel: 'Inspecter la flèche pour défauts',
    //  icon: pedal_brake,
  },
  {
    id: 'A14',
    label: 'Inspect man cage',
    subLabel: 'Inspecter la cage',
    //  icon: pedal_brake,
  },
  {
    id: 'A15',
    label: 'Inspect lifting hook & safety latch',
    subLabel: 'Inspecter le crochet de lévage et son locket de sécurité',
    //  icon: pedal_brake,
  },
  {
    id: 'A16',
    label: 'Inspect operating station for defects',
    subLabel: "Inspecter la station d'opération pour défauts",
    //  icon: pedal_brake,
  },
  {
    id: 'A17',
    label: 'Ensure emergency switch is working',
    subLabel: 'Inspecter la cage',
    //  icon: pedal_brake,
  },
  {
    id: 'A18',
    label: 'Inspect all hydraulic cylinders for defects',
    subLabel: 'Inspecter tous les vérins hydrauliques pour déceler tout défaut',
    //  icon: pedal_brake,
  },
  {
    id: 'A19',
    label: 'Inspect container lock for defects (Stacker)',
    subLabel:
      'Inspecter le verrou du conteneur pour déceler tout défaut (Empileur)',
    //  icon: pedal_brake,
  },
  {
    id: 'A20',
    label: 'Inspect the mast for defects (Fork Lift)',
    subLabel: 'Inspecter le mât pour déceler tout défaut (Chariot élévateur)',
    //  icon: pedal_brake,
  },
];

export const itemsFolkB = [
  {
    id: 'B1',
    label: 'Check all the windows for damage',
    subLabel: 'Vérifier les fenêtres pour tout dommage',
    icon: windowField,
  },
  {
    id: 'B2',
    label: 'Check wipers condition',
    subLabel: "Vérifer l'état des essuie-glaces",
    icon: wiper,
  },
  {
    id: 'B3',
    label: 'Check all mirrors for damage',
    subLabel: 'Vérifer tous les retroviseurs pour tout dommage',
    icon: rearmirror,
  },
  {
    id: 'B4',
    label: 'Ensure all lights are working',
    subLabel: 'Se rassurer que toutes les lumières fonctionnent',
    icon: carlight,
  },
  {
    id: 'B5',
    label: 'Ensure all indicators are working',
    subLabel: 'Se rassurer que tous les clignotants fonctionnent',
    icon: indicators,
  },
  {
    id: 'B6',
    label: 'Check tires condition',
    subLabel: "Vérifer l'ètat des pneus",
    icon: tyrestate,
  },
  {
    id: 'B7',
    label: 'Inspect wheel nuts',
    subLabel: 'Inspecter les écrous de roue',
    icon: rim,
  },
  {
    id: 'B8',
    label: 'Check wheel nut indicators',
    subLabel: 'Vérifier les indicateurs des écrous de roue',
    icon: wheelshock,
  },
  {
    id: 'B9',
    label: 'Check flag pole',
    subLabel: 'Vérifer le mât du drapelet',
    icon: flags,
  },
  {
    id: 'B10',
    label: 'Check all seat belts for damage',
    subLabel:
      'Vérifier toutes les ceintures de sécurité pour pour tout dommage',
    icon: seatbelt,
  },
  {
    id: 'B11',
    label: 'Check all seats for damage',
    subLabel: 'Vérifier tous les sieges pour tout dommage',
    icon: seats,
  },
  {
    id: 'B12',
    label: 'Ensure the horn is working',
    subLabel: 'Se rassurer que le kiaxon fonctionne',
    icon: horn,
  },
  {
    id: 'B13',
    label: 'Ensure reverse alarm is working',
    subLabel: "Se rassurer que l'alarme de marche arrière fonctionne",
    icon: alarmSound,
  },
  {
    id: 'B14',
    label: 'Ensure flashing beacons is working',
    subLabel: 'Se rassurer que le girophare fonctionne',
    icon: flashLight,
  },
  {
    id: 'B15',
    label: 'Check radio (Surface & UG mining)',
    subLabel:
      'Vérifier la radio de communication (surface et mine souterraine)',
    icon: radio,
  },
  {
    id: 'B16',
    label: 'Record body damage',
    subLabel: 'Rapporter tout dommage sur la carroserie',
    icon: bodyDamaged,
  },

  {
    id: 'B17',
    label: 'Inspect forks and attachments for damage',
    subLabel:
      'Inspecter les fourches et les accessoires pour déceler tout dommage',
    // icon: bodyDamaged,
  },
  {
    id: 'B18',
    label: 'Inspect step ladders and plat forms',
    subLabel: 'Inspecter les echelles et les plates-formes',
    // icon: bodyDamaged,
  },
];

export const itemsFolkC = [
  {
    id: 'C1',
    label: 'Ensure the window washer fluid is full',
    subLabel: 'Se rassurer que le liquide lave-glace est plein',
    icon: windowWasher,
  },
  {
    id: 'C2',
    label: 'Ensure the licenses & permit are valid ',
    subLabel: 'Se rassurer que le permis de conduire  sont valides',
    icon: license,
  },
  {
    id: 'C3',
    label: 'Ensure fuel tank  is full',
    subLabel: 'Se rassurer que le reservoir de carburant est plein',
    icon: fuel,
  },
  {
    id: 'C4',
    label: 'Inspect fire extinguisher',
    subLabel: "Inspect l'extincteur",
    icon: fire_extinguisher,
  },
  {
    id: 'C5',
    label: 'Check wheel chocks',
    subLabel: 'Vérifier les cales de roue',
    icon: wheelshock,
  },
  {
    id: 'C6',
    label: 'Check first aid kit ',
    subLabel: 'Vérifier la trousse de premiers soins',
    icon: first_kit_aid,
  },
  {
    id: 'C7',
    label: 'Check safety triangles',
    subLabel: 'Vérifier les triangles de sécurité',
    icon: safety_triangle,
  },
  {
    id: 'C8',
    label: 'Ensure vehicle ID is clear',
    subLabel:
      "Se rassurer que le numéro d'identification du  véhicle est visible",
    icon: id,
  },
  {
    id: 'C9',
    label: 'Ensure the vehicle is clean',
    subLabel: 'Se rassurer que le véhicle est propre',
    icon: car_clean,
  },
];

export const itemsTruckA = [
  {
    id: 'A1',
    label: 'Are you fit to operate the vehicle',
    subLabel: 'Etes-vous apte a conduire ce vehicule',
    icon: fit,
  },
  {
    id: 'A2',
    label: 'Check engine oil level',
    subLabel: "Vérifier le niveau d'huile moteur",
    icon: oil,
  },
  {
    id: 'A3',
    label: 'Check radiator level',
    subLabel: 'Vérifier le niveau de refroidisseur du radiateur,',
    icon: cooling,
  },
  {
    id: 'A4',
    label: 'Check brake fluid level',
    subLabel: 'Vérifier le niveau du liquide de frein',
    icon: brake_fluid,
  },
  {
    id: 'A5',
    label: 'Check steering fluid level',
    subLabel: "Vérifier le niveau d'huile de direction",
    icon: steering,
  },
  {
    id: 'A6',
    label: 'Check for any leaks',
    subLabel: 'Vérifier toutes fuites',
    icon: leaks,
  },
  {
    id: 'A7',
    label: 'Check all V-belt for damage',
    subLabel: 'Vérifier toutes les courroies pour les dommage',
    icon: v_belt,
  },
  {
    id: 'A8',
    label: 'Inspect battery & clamps',
    subLabel: 'Inspecter la batterie et les anneaux de fixations',
    icon: battery,
  },
  {
    id: 'A9',
    label: 'Check for warning signs on cluster',
    subLabel: "Vérifier tout signal d'avertissement sur le secteur",
    icon: warning,
  },
  {
    id: 'A10',
    label: 'Ensure park brake is working',
    subLabel: 'Se rassurer que le frein de stationnement fonctionne',
    icon: parking_brake,
  },
  {
    id: 'A11',
    label: 'Ensure service brake is working',
    subLabel: 'Se rassurer que le frein de service fonctionne',
    icon: pedal_brake,
  },

  {
    id: 'A0',
    title: 'HIAB, Cherry Picker - Truck / Camion-grue, nacelle élevatrice',
  },

  {
    id: 'A12',
    label: 'Check if load chart is available',
    subLabel: 'Vérifier si la grille des charges est disponible',
    //  icon: pedal_brake,
  },
  {
    id: 'A13',
    label: 'Inspect boom for defects',
    subLabel: 'Inspecter la flèche pour défauts',
    //  icon: pedal_brake,
  },
  {
    id: 'A14',
    label: 'Inspect man cage',
    subLabel: 'Inspecter la cage',
    //  icon: pedal_brake,
  },
  {
    id: 'A15',
    label: 'Inspect lifting hook & safety latch',
    subLabel: 'Inspecter le crochet de lévage et son locket de sécurité',
    //  icon: pedal_brake,
  },
  {
    id: 'A16',
    label: 'Inspect operating station for defects',
    subLabel: "Inspecter la station d'opération pour défauts",
    //  icon: pedal_brake,
  },
  {
    id: 'A17',
    label: 'Ensure emergency switch is working',
    subLabel: 'Inspecter la cage',
    //  icon: pedal_brake,
  },
];

export const itemsTruckB = [
  {
    id: 'B1',
    label: 'Check all the windows for damage',
    subLabel: 'Vérifier les fenêtres pour tout dommage',
    icon: windowField,
  },
  {
    id: 'B2',
    label: 'Check wipers condition',
    subLabel: "Vérifer l'état des essuie-glaces",
    icon: wiper,
  },
  {
    id: 'B3',
    label: 'Check all mirrors for damage',
    subLabel: 'Vérifer tous les retroviseurs pour tout dommage',
    icon: rearmirror,
  },
  {
    id: 'B4',
    label: 'Ensure all lights are working',
    subLabel: 'Se rassurer que toutes les lumières fonctionnent',
    icon: carlight,
  },
  {
    id: 'B5',
    label: 'Ensure all indicators are working',
    subLabel: 'Se rassurer que tous les clignotants fonctionnent',
    icon: indicators,
  },
  {
    id: 'B6',
    label: 'Check tires condition',
    subLabel: "Vérifer l'ètat des pneus",
    icon: tyrestate,
  },
  {
    id: 'B7',
    label: 'Inspect wheel nuts',
    subLabel: 'Inspecter les écrous de roue',
    icon: rim,
  },
  {
    id: 'B8',
    label: 'Check wheel nut indicators',
    subLabel: 'Vérifier les indicateurs des écrous de roue',
    icon: wheelshock,
  },
  {
    id: 'B9',
    label: 'Check flag pole',
    subLabel: 'Vérifer le mât du drapelet',
    icon: flags,
  },
  {
    id: 'B10',
    label: 'Check all seat belts for damage',
    subLabel:
      'Vérifier toutes les ceintures de sécurité pour pour tout dommage',
    icon: seatbelt,
  },
  {
    id: 'B11',
    label: 'Check all seats for damage',
    subLabel: 'Vérifier tous les sieges pour tout dommage',
    icon: seats,
  },
  {
    id: 'B12',
    label: 'Ensure the horn is working',
    subLabel: 'Se rassurer que le kiaxon fonctionne',
    icon: horn,
  },
  {
    id: 'B13',
    label: 'Ensure reverse alarm is working',
    subLabel: "Se rassurer que l'alarme de marche arrière fonctionne",
    icon: alarmSound,
  },
  {
    id: 'B14',
    label: 'Ensure flashing beacons is working',
    subLabel: 'Se rassurer que le girophare fonctionne',
    icon: flashLight,
  },
  {
    id: 'B15',
    label: 'Check radio (Surface & UG mining)',
    subLabel:
      'Vérifier la radio de communication (surface et mine souterraine)',
    icon: radio,
  },
  {
    id: 'B16',
    label: 'Record body damage',
    subLabel: 'Rapporter tout dommage sur la carroserie',
    icon: bodyDamaged,
  },

  // {
  //   id: 'B0',
  //   title:
  //     ' Service, Fuel, Fire - Truck / Camions de service, de carburant anti-incendie',
  // },
  {
    id: 'B17',
    label: 'Inspect operating station for defects',
    subLabel: "Inspecter la station d'opération pour défauts ",
    // icon: bodyDamaged,
  },
  {
    id: 'B18',
    label: 'Ensure tanks is secured',
    subLabel: 'Se rassurer que le réservoir est sécurisé',
    // icon: bodyDamaged,
  },
  {
    id: 'B19',
    label: 'Inspect hoses and reels',
    subLabel: 'Inspecter les flexibles et rouleaux',
    // icon: bodyDamaged,
  },
  {
    id: 'B20',
    label: 'Inspect doors and cabin',
    subLabel: 'Inspecter les portières et la cabine',
    // icon: bodyDamaged,
  },
  {
    id: 'B21',
    label: 'Inspect pumps and valves',
    subLabel: 'Inspecter les pompes et vannes',
    // icon: bodyDamaged,
  },
];

export const itemsTruckC = [
  {
    id: 'C1',
    label: 'Ensure the window washer fluid is full',
    subLabel: 'Se rassurer que le liquide lave-glace est plein',
    icon: windowWasher,
  },
  {
    id: 'C2',
    label: 'Ensure the licenses & permit are valid ',
    subLabel: 'Se rassurer que le permis de conduire  sont valides',
    icon: license,
  },
  {
    id: 'C3',
    label: 'Ensure fuel tank  is full',
    subLabel: 'Se rassurer que le reservoir de carburant est plein',
    icon: fuel,
  },
  {
    id: 'C4',
    label: 'Inspect fire extinguisher',
    subLabel: "Inspect l'extincteur",
    icon: fire_extinguisher,
  },
  {
    id: 'C5',
    label: 'Check wheel chocks',
    subLabel: 'Vérifier les cales de roue',
    icon: wheelshock,
  },
  {
    id: 'C6',
    label: 'Check first aid kit ',
    subLabel: 'Vérifier la trousse de premiers soins',
    icon: first_kit_aid,
  },
  {
    id: 'C7',
    label: 'Check safety triangles',
    subLabel: 'Vérifier les triangles de sécurité',
    icon: safety_triangle,
  },
  {
    id: 'C8',
    label: 'Ensure vehicle ID is clear',
    subLabel:
      "Se rassurer que le numéro d'identification du  véhicle est visible",
    icon: id,
  },
  {
    id: 'C9',
    label: 'Ensure the vehicle is clean',
    subLabel: 'Se rassurer que le véhicle est propre',
    icon: car_clean,
  },

  // {
  //   id: 'C',
  //   title:
  //     ' Service, Fuel, Fire - Truck / Camions de service, de carburant anti-incendie',
  // },

  {
    id: 'C10',
    label: 'Inspect ladders',
    subLabel: 'inspecter les echelles',
    // icon: car_clean,
  },
];
