import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Rectangle,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import { useQuery, useMutation } from '@tanstack/react-query';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { BsFiletypePdf } from 'react-icons/bs';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useReactToPrint } from 'react-to-print';
// import ShareIcon from '@mui/icons-material/Share';
import { formatDate } from '../../../utilities//dateFormat';
import SpotServices from '../../../../service/spot';
import AssetService from '../../../../service/asset';
import DepartmentServices from '../../../../service/department';
import InspectionServices from '../../../../service/inspection';
import CustomPaper from '../../../utilities/customPaper';
import ExportReactCSV from '../../../utilities/csv';
import ExcelExport from '../../../utilities/xlxs';
import TableData from '../../../utilities/table';
import { ReportPrinter } from './reportPrint';

export const csvHeaders = [
  {
    label: 'Inspection Id',
    key: 'inspectionId',
  },
  {
    label: 'Asset',
    key: 'asset',
  },
  {
    label: 'Department',
    key: 'department_name',
  },
  {
    label: 'Mileage',
    key: 'mileage',
  },
  {
    label: 'Scores',
    key: 'scores',
  },
  {
    label: 'Total Items',
    key: 'total_items',
  },
  {
    label: 'Shift',
    key: 'shift',
  },
  {
    label: 'Created At',
    key: 'createdAt',
  },
];

const assetCol = [
  {
    field: 'assetId',
    headerName: 'Asset',
    width: 200,
  },

  {
    field: 'target',
    headerName: 'Target',
    width: 180,
  },
  {
    field: 'prestart_count',
    headerName: 'Current Prestart',
    width: 150,
  },
  {
    field: 'target_met_percentage',
    headerName: 'Target Met Percentage',
    width: 200,
  },
  {
    field: 'serviceCycle',
    headerName: 'Service Cycle',
    width: 150,
  },
  {
    field: 'serviceInterval',
    headerName: 'Service Interval',
    width: 160,
  },
];
export const InspectionReports = () => {
  const [depart, setDepart] = React.useState(null);
  const [departId, setDepartId] = React.useState(0);
  const [date, setDate] = React.useState(null);
  const [csv, setCsv] = React.useState([]);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [stat, setStat] = React.useState(null);
  const [cat, setCat] = React.useState(null);
  // const [subDepart, setSubDepart] = React.useState(null);
  const { handleSubmit, register, reset } = useForm();
  const [dataFilters, setdataFilters] = React.useState([]);
  const [assetData, setAssetData] = React.useState([]);
  const [final, setFinal] = React.useState();

  const componentRef = React.useRef();
  const promiseResolveRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'report',

    onBeforeGetContent: () => {
      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_report'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { data: sites, isLoading: isSite } = useQuery({
    queryKey: ['sites_report'],
    queryFn: async () => {
      const resp = await SpotServices.allSpots();
      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { data: categories, isLoading: isCategory } = useQuery({
    queryKey: ['category_report'],
    queryFn: async () => {
      const resp = await AssetService.allAssetLabel('category');
      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { mutate: inspectionFilters, isPending: inspectionFilterPendiing } =
    useMutation({
      mutationFn: InspectionServices.departmentReport,
      onSuccess: (data) => {
        setCsv(data);

        const resp = data.map((e) => ({
          name: e.name,
          total: e.total_target_met_percentage,
          height: 100,
        }));
        console.log(resp);
        const result = Object.values(
          resp.reduce((r, o) => {
            r[o.name] = r[o.name] || {
              name: o.name,
              total: 0,
              height: o.height,
            };
            r[o.name].total = o.total;
            return r;
          }, {})
        );
        const total = data.map((e) => ({
          name: date,
          total: e.total_target_met_percentage,
          height: 100,
        }));

        const finalReport = Object.values(
          total.reduce((r, o) => {
            r[o.name] = r[o.name] || {
              name: o.name,
              total: 0,
              height: o.height,
            };
            r[o.name].total += o.total;
            return r;
          }, {})
        );
        setFinal(finalReport);
        setdataFilters(result);
      },
    });

  const handleClear = () => {
    setDepart(null);
    setDepartId(0);
    // setSubDepart(null);
    setdataFilters([]);
    reset();
  };

  const { mutate: asset, isPending: isAssetPendiing } = useMutation({
    mutationFn: InspectionServices.assetReport,
    onSuccess: (data) => {
      setAssetData(data);
    },
  });

  const filterSearch = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='row' spacing={2} alignContent='center'>
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>Month</Typography>
            <TextField
              required
              size='small'
              fullWidth
              {...register('month')}
              type='month'
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>Department</Typography>
            <Autocomplete
              fullWidth={true}
              size='small'
              id='combo-box-demo'
              PaperComponent={CustomPaper}
              options={departments ? departments : []}
              value={depart}
              loading={isDepartment}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                // setSubDepart(null);
                if (newValue) {
                  setDepart(newValue);
                  setDepartId(newValue.id);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' required />
              )}
            />
          </Grid>
          {/* {depart?.id > 0 && (
            <Grid item xs={12} sm={3}>
              <Typography variant='subtitle2'>Sub Department</Typography>
              <Autocomplete
                fullWidth={true}
                disabled={depart?.id !== 0 ? false : true}
                size='small'
                id='combo-box-demo'
                PaperComponent={CustomPaper}
                options={subDepartment ? subDepartment : []}
                value={subDepart}
                loading={isLoadingSubDepartment}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setSubDepart(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size='small' required />
                )}
              />
            </Grid>
          )} */}
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>Sites</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={sites}
              value={stat}
              fullWidth={true}
              loading={isSite}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setStat(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' required />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>Asset Category</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={categories}
              value={cat}
              fullWidth={true}
              loading={isCategory}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setCat(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' required />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} container gap={2}>
            <Button
              sx={{
                textTransform: 'capitalize',
                height: '35px',
                alignSelf: 'end',
                backgroundColor: '#b31313',
                color: 'white',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#b31313',
                },
              }}
              variant='filled'
              onClick={() => handleClear()}
            >
              Cancel
            </Button>
            <Button
              sx={{
                textTransform: 'capitalize',
                height: '35px',
                alignSelf: 'end',
                backgroundColor: 'primary.light',
                color: 'white',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
              variant='filled'
              type='submit'
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };
  const onSubmit = (data, e) => {
    e.preventDefault();

    const formattedDate = formatDate(data.month);
    setDate(formattedDate);

    inspectionFilters({
      department: depart?.id,
      // subDepartment: subDepart?.id !== undefined ? subDepart.id : 0,
      category: cat?.id,
      site: stat?.id,
      month: data.month,
    });

    asset({
      department: depart?.id,
      // subDepartment: subDepart?.id !== undefined ? subDepart.id : 0,
      category: cat?.id,
      site: stat?.id,
      month: data.month,
    });
  };

  return (
    <Grid container direction='column' gap={6}>
      <Grid
        item
        container
        direction={'column'}
        gap={4}
        sx={{
          padding: '20px 20px',
          boxShadow: '0px 0px 20px 5px #cdcdcd4d',
          backgroundColor: '#ffffff3d',
          backdropFilter: 'blur(20px)',
          borderRadius: '10px',
        }}
      >
        <Grid item container direction={'row'}>
          <Grid item xs={12} sm={8}>
            <Typography variant='h5'>Filter by: </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent='flex-end' gap={3}>
            {/* <IconButton
              sx={{
                textTransform: 'capitalize',
                color: 'primary.light',
              }}
            >
              <ShareIcon />
            </IconButton> */}
            <Button
              variant='outlined'
              size='small'
              onClick={handlePrint}
              startIcon={<BsFiletypePdf />}
              disabled={dataFilters.length > 0 ? false : true}
            >
              PDF
            </Button>
            <ExportReactCSV
              csvData={csv}
              csvHeaders={csvHeaders}
              fileName={`report- ${date}`}
            />
            <ExcelExport data={csv} fileName={`report- ${date}`} />
          </Grid>
        </Grid>
        <Grid item>{filterSearch()}</Grid>
      </Grid>

      {dataFilters.length > 0 ? (
        <Grid item container direction={'column'} gap={7}>
          <Grid item>
            <Typography variant='h4'>{date}</Typography>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sm={10}>
              <ResponsiveContainer height={400}>
                <BarChart
                  data={dataFilters}
                  margin={{
                    top: 20,
                    bottom: 15,
                  }}
                  radius={8}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    angle={10}
                    textAnchor='start'
                    interval={0}
                    dy={0}
                  />
                  <YAxis dataKey='height' />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    dataKey='total'
                    stackId='a'
                    // label={<Typography>hh</Typography>}
                    fill='#2D76AB'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  >
                    <LabelList
                      dataKey='total'
                      position='top'
                      angle={0}
                      offset={25}
                      fill='red'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ResponsiveContainer height={400}>
                <BarChart
                  data={final}
                  margin={{
                    top: 20,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    // angle={10}
                    // textAnchor='end'
                    // interval={0}
                    dy={0}
                  />
                  <YAxis dataKey='height' />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    dataKey='total'
                    stackId='a'
                    // label={<Typography>hh</Typography>}
                    fill='#2D76AB'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  >
                    <LabelList
                      dataKey='total'
                      position='top'
                      angle={0}
                      offset={25}
                      fill='red'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            {departId > 0 && (
              <Grid
                item
                xs={12}
                sm={12}
                container
                direction='column'
                gap={2}
                paddingTop={'40px'}
              >
                <Grid item>
                  <Typography variant='h5'>
                    Prestart by Department of {date}
                  </Typography>
                </Grid>
                <Grid item>
                  <ResponsiveContainer height={400}>
                    <BarChart
                      data={assetData?.map((item) => ({
                        assetId: item.assetId,
                        target_met_percentage: item.target_met_percentage,
                        total: 100,
                      }))}
                      margin={{
                        top: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='assetId'
                        angle={10}
                        textAnchor='start'
                        interval={0}
                        dy={0}
                      />
                      <YAxis dataKey='total' />
                      {/* <Tooltip /> */}
                      {/* <Legend /> */}
                      <Bar
                        dataKey='target_met_percentage'
                        stackId='a'
                        // label={<Typography>hh</Typography>}
                        fill='#2D76AB'
                        activeBar={<Rectangle fill='pink' stroke='blue' />}
                      >
                        <LabelList
                          dataKey='target_met_percentage'
                          position='top'
                          angle={0}
                          offset={25}
                          fill='red'
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='column'
              gap={2}
              paddingTop={'40px'}
            >
              <Grid item>
                <Typography variant='h5'>
                  Prestart by Asset of {date}
                </Typography>
              </Grid>
              <Grid item>
                <TableData
                  cols={assetCol}
                  rows={assetData || []}
                  maxWidthMob='84vw'
                  height='400px'
                  isLoading={isAssetPendiing}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          padding='30px 0px'
        >
          <Typography variant='body2' color='primary.light'>
            {inspectionFilterPendiing
              ? 'loading ....'
              : 'No data, Please select'}
          </Typography>
        </Grid>
      )}
      {/* can only hide display in Chrome and firefox */}
      <div style={{ contentVisibility: 'hidden' }}>
        {dataFilters.length > 0 && (
          <ReportPrinter
            ref={componentRef}
            data={dataFilters}
            finalData={final}
            assetData={departId > 0 ? assetData : []}
            isPrinting={isPrinting}
            date={date}
            promiseResolveRef={promiseResolveRef}
          />
        )}
      </div>
    </Grid>
  );
};
