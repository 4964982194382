import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityHandler = ({ timeout = 300000 }) => {
  // Default timeout is 5 minutes (300000 ms)
  const navigate = useNavigate();
  const timer = useRef(null);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(logOut, timeout);
  };

  const logOut = () => {
    sessionStorage.removeItem('user');
    sessionStorage.clear();
    navigate('/login'); // or any logout action you need
  };

  useEffect(() => {
    // Events that reset the timer
    const events = ['mousemove', 'keydown', 'touchstart'];

    // Event handler to reset the timer
    const reset = () => resetTimer();

    // Add event listeners
    events.forEach((event) => window.addEventListener(event, reset));

    // Set the initial timer
    resetTimer();

    // Cleanup: remove event listeners on unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, reset));
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export default InactivityHandler;
