import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const CustomPaper = styled(Paper)({
  backgroundColor: '#ffffff80',
  backdropFilter: 'blur(20px)',
  color: 'primary.main',
});

export default CustomPaper;
