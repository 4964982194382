import React from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';

import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import IssueServices from '../../../service/issue';
import Alert from '../../constant/alert';

export const AddCategory = ({ handleClose, category }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: category,
  });
  const queryClient = useQueryClient();
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: category ? IssueServices.category : IssueServices.addCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories_list_issue'] });
      reset();
    },
  });
  const onSubmit = (data, e) => {
    e.preventDefault();
    const { name } = data;
    if (category) {
      mutate({ method: 'patch', id: category.id, name });
    } else {
      mutate({ name });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={4}>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item container direction={'column'} gap={1} xs={12} sm={12}>
                <Typography variant='subtitle2'>Name</Typography>
                <TextField
                  required={true}
                  fullWidth={true}
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size='small'
                  {...register('name')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error.message}, Please contact the management team
              </Typography>
            )}
          </Grid>
          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                  // '&:hover': {},
                }}
                variant='contained'
                autoFocus
                type='submit'
              >
                {category ? 'Update' : 'Submit'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
AddCategory.propTypes = {
  handleClose: PropTypes.func,
  category: PropTypes.objectOf(PropTypes.shape),
};
