import React from 'react';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Grid, Typography, Avatar, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import InspectionServices from '../../../service/inspection';
import { useNavigate } from 'react-router-dom';
import AssetService from '../../../service/asset';

export const RecentView = () => {
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ['total_items'],
    queryFn: async () => {
      const users = await UserServices.getAllUser();
      const inspections = await InspectionServices.allInspections();
      const assets = await AssetService.allAssets();
      return {
        assets: assets.length,
        users: users.length,
        inspections: inspections.length,
      };
    },
  });

  const cardView = [
    {
      id: 1,
      title: 'Assets',
      desc: 'Total assets',
      total_Items: data ? data.assets : 0,
      icon: <BarChartOutlinedIcon />,
    },
    {
      id: 2,
      title: 'Users',
      desc: 'Total users',
      total_Items: data ? data.users : 0,
      icon: <BarChartOutlinedIcon />,
    },
    {
      id: 3,
      title: 'Pre Start',
      desc: 'Total Prestarts ',
      total_Items: data ? data.inspections : 0,
      icon: <BarChartOutlinedIcon />,
    },
  ];

  return (
    <Grid container direction='row' gap='48px'>
      {cardView.map((item, index) => (
        <Grid item xs={12} sm={6} md={3.6} key={index}>
          <Card
            elevation={0}
            onClick={() => {
              if (item.id === 1) {
                navigate('/assets');
              } else if (item.id === 2) {
                navigate('/users');
              } else {
                navigate('/prestart/');
              }
            }}
            sx={{
              backgroundColor: '#ffffffa8',
              borderRadius: '10px',
              boxShadow: ' 0px 0px 20px 5px #cdcdcd4d',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Grid
              container
              onClick={() => {}}
              direction='column'
              sx={{
                boxShadow: '0px 0px 20px 5px #9bbbd433',
                backdropFilter: 'blur(20px)',
              }}
              gap={2}
            >
              <Grid
                item
                xs={12}
                container
                direction='column'
                gap={4}
                sx={{
                  backgroundColor: '#2d77ab0d',
                  padding: '10px 20px',
                }}
              >
                <Grid item container justifyContent='space-between'>
                  <Typography variant='h4' color='primary.light'>
                    {item.total_Items}
                  </Typography>
                  <Avatar sx={{ backgroundColor: '#2d77ab80' }}>
                    {item.icon}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant='h5'>{item.title}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                sx={{
                  padding: '10px 20px',
                }}
              >
                <Typography variant='body2'>{item.desc}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
