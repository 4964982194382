import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import AssetService from '../../../service/asset';
import CustomPaper from '../../utilities/customPaper';
import serviceServices from '../../../service/service';
import Alert from '../../constant/alert';

const inputField = [
  {
    label_name: 'Serviced Date',
    form: 'serviced_date',
    type: 'date',
    required: true,
  },
  {
    label_name: 'Serviced Smr',
    form: 'last_service_smr',
    type: 'number',
    required: true,
  },

  {
    label_name: 'JobCard Number',
    form: 'job_card_number',
    type: 'text',
    required: true,
  },

  {
    label_name: 'Comments',
    form: 'comments',
    type: 'text',
    multiline: true,
    rows: 3,
    required: true,
  },
];

const serviceTypeKm = [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000];
const serviceTypeHour = [250, 500, 750, 1000, 1250, 1500, 1750, 2000];

export const AddService = ({ handleClose, service }) => {
  // state variables
  const [asset, setAsset] = React.useState(null);
  const [serviceType, setServiceType] = React.useState(null);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: service,
  });
  const queryClient = useQueryClient();

  const { data: Asserts, isLoading: isAsset } = useQuery({
    queryKey: ['service_assets'],
    queryFn: () => AssetService.allAssets(),
  });

  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: service ? serviceServices.service : serviceServices.addService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['services'] });
      reset();
      setAsset(null);
    },
  });

  // const
  const onSubmit = (data, e) => {
    e.preventDefault();
    // console.log(data);
    const {
      id: assetId,
      category,
      department,
      subDepartment,
      manufacture,
    } = asset;
    const { comments, job_card_number, last_service_smr, serviced_date } = data;

    if (service) {
      // mutate({});
    } else {
      mutate({
        assetId,
        category,
        last_service_smr,
        department,
        subDepartment,
        manufacture,
        job_card_number,
        comments,
        serviced_date,
        user: 1,
        service_type: serviceType,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data.message} handleClose={handleClose} />
      ) : (
        <Grid container direction='column' gap={3}>
          <Grid item container direction={'column'} gap={1}>
            <Typography>Asset ID</Typography>
            <Autocomplete
              autoHighlight
              id='combo-box-demo'
              size='small'
              options={!isAsset ? Asserts : []}
              value={asset}
              fullWidth={true}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.assetId}
              onChange={(event, newValue) => {
                setServiceType(null);
                setAsset(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label='Please select ' required />
              )}
            />
          </Grid>

          {asset && (
            <Grid item container direction={'column'} gap={1}>
              <Typography>Service type</Typography>
              <Autocomplete
                autoHighlight
                id='combo-box-demo'
                size='small'
                options={
                  asset.smrType === 'HRS' ? serviceTypeHour : serviceTypeKm
                }
                value={serviceType}
                fullWidth={true}
                PaperComponent={CustomPaper}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  setServiceType(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Please select  service type'
                    required
                  />
                )}
              />
            </Grid>
          )}
          <Grid item container direction='column' gap={2}>
            {inputField.map((value, i) => (
              <Grid
                item
                container
                direction={'column'}
                gap={1}
                key={i}
                xs={12}
                sm={6}
              >
                <Typography variant='subtitle2'>{value.label_name}</Typography>
                <TextField
                  {...value}
                  {...register(`${value.form}`)}
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size='small'
                />
              </Grid>
            ))}
          </Grid>
          {isError && (
            <Grid item>
              <Typography variant='caption' color='red'>
                {error?.response.data?.message}, Please contact the management
                team
              </Typography>
            </Grid>
          )}
          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: '#2D77AB',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2D77AB',
                  },
                }}
                variant='contained'
                type='submit'
              >
                {service ? 'Update' : 'Save'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
