import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
// import SpotServices from '../../../service/spot';
import UserServices from '../../../service/user';
import CustomPaper from '../../utilities/customPaper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const Author = ({ user }) => {
  const queryClient = useQueryClient();
  const [isEdit, setisEdit] = React.useState(false);
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const [depart, setDepart] = React.useState(null);
  const [group, setGroup] = React.useState(null);
  const [isLoad, setIsLoad] = React.useState(false);
  const [isPassword, setisPassword] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const { data: departments } = useQuery({
    queryKey: ['departments'],
    queryFn: async () => {
      const data = await DepartmentServices.allDepartments();
      const defaultIndex = user
        ? data.findIndex(({ id }) => id === user.department)
        : null;
      return {
        data: data,
        defaultIndex: defaultIndex,
      };
    },
  });

  const { data: groups } = useQuery({
    queryKey: ['groups_data'],
    queryFn: async () => {
      const data = await UserServices.getAllGroup();
      // console.log(data);
      const defaultIndex = user
        ? data?.findIndex(({ id }) => id === user?.groups[0]['id'])
        : null;

      return { data: data, defaultIndex: defaultIndex };
    },
  });

  const { mutate } = useMutation({
    mutationFn: !isPassword
      ? UserServices.updateUser
      : UserServices.updateUserPassword,
    onSuccess: () => {
      reset();
      setDepart(null);
      setisEdit(false);
      setIsLoad(false);
      setisPassword(false);
      setIsLoading2(false);
      setGroup(null);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['groups_data'] });
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoad(true);
    if (isPassword === true) {
      setIsLoading2(true);
      mutate({
        id: data.id,
        password: data.password,
      });
    } else {
      mutate({
        id: user.id,
        method: 'patch',
        department: depart?.id || user.department,
        group: group?.id,
        group_name: group?.name,
      });
    }
  };

  console.log(user);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' gap={3}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: '20px 30px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Grid container direction='column' gap={4}>
              <Grid item>
                <Grid container direction='row' justifyContent='space-between'>
                  <Grid item>
                    <Typography variant='h6'>Authorization details</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction='row' gap={2}>
                      {isEdit === true && (
                        <Button
                          sx={{
                            height: 30,
                            border: '1px solid #2D77AB',
                          }}
                          type='submit'
                          variant='outlined'
                          startIcon={
                            isLoad ? <CircularProgress /> : <SaveIcon />
                          }
                        >
                          Save
                        </Button>
                      )}
                      <Button
                        sx={{
                          height: 30,
                          border: '1px solid #2D77AB',
                        }}
                        onClick={() => {
                          if (isEdit === true) {
                            setisEdit(false);
                          } else {
                            reset(user);
                            setisEdit(true);
                          }
                        }}
                        variant='outlined'
                        startIcon={
                          isEdit === true ? (
                            <CloseIcon />
                          ) : (
                            <EditIcon fontSize='10px' />
                          )
                        }
                      >
                        {isEdit === true ? 'Close' : 'Edit'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction='column' gap={1}>
                <Typography variant='subtitle2' color='secondary.light'>
                  Department
                </Typography>
                {isEdit === true ? (
                  <Autocomplete
                    id='combo-box-demo'
                    options={departments ? departments.data : []}
                    value={
                      user
                        ? depart ||
                          departments?.data[departments.defaultIndex] ||
                          null
                        : depart || null
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setDepart(newValue);
                    }}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : (
                  <Stack direction='row' spacing={1}>
                    {user?.departments?.map((item) => (
                      <Chip
                        label={item.name}
                        onClick={() => {}}
                        onDelete={() => {}}
                      />
                    ))}
                  </Stack>
                )}
              </Grid>

              <Grid item container direction='column' gap={1}>
                <Typography variant='subtitle2' color='secondary.light'>
                  Role
                </Typography>
                {isEdit === true ? (
                  <Autocomplete
                    id='combo-box-demo'
                    options={groups.data ?? []}
                    value={
                      user
                        ? group || groups.data[groups.defaultIndex]
                        : group || null
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setGroup(newValue);
                    }}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField {...params} size='medium' />
                    )}
                  />
                ) : (
                  <Stack direction='row' spacing={1}>
                    {user.groups.length > 0
                      ? user.groups?.map((item) => (
                          <Chip
                            label={item.name}
                            onClick={() => {}}
                            // onDelete={() => {}}
                          />
                        ))
                      : '-----'}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: '20px 30px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Grid container direction='column' gap={2}>
              <Grid container direction='row' justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h6'>Password</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction='row' gap={2} alignItems='center'>
                    <IconButton onClick={() => setIsVisible(!isVisible)}>
                      {!isVisible ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                    {isPassword === true && (
                      <Button
                        sx={{
                          height: 30,
                          border: '1px solid #2D77AB',
                        }}
                        type='submit'
                        variant='outlined'
                        startIcon={
                          isLoading2 ? <CircularProgress /> : <SaveIcon />
                        }
                        disableElevation
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      sx={{
                        height: 30,
                        border: '1px solid #2D77AB',
                        '&:hover': {
                          backgroundColor: '#032842',
                          color: 'white',
                        },
                      }}
                      disableElevation
                      onClick={() => {
                        if (isPassword === true) {
                          setisPassword(false);
                        } else {
                          reset(user);
                          setisPassword(true);
                        }
                      }}
                      variant='outlined'
                      startIcon={
                        isPassword === true ? (
                          <CloseIcon />
                        ) : (
                          <EditIcon fontSize='10px' />
                        )
                      }
                    >
                      {isPassword === true ? 'Close' : 'Edit'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {isPassword ? (
                  <Grid container direction='column'>
                    <Grid item>
                      <TextField
                        variant='filled'
                        required
                        label='New password'
                        {...register('password')}
                        fullWidth
                        size='small'
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        variant='filled'
                        label='Confirm password'
                        required
                        {...register('confirm_password', {
                          validate: (val) => {
                            if (watch('password') !== val) {
                              return 'Your passwords do no match';
                            }
                          },
                        })}
                        fullWidth
                        size='small'
                      />
                      {errors && (
                        <Typography variant='caption' color='red'>
                          {errors?.confirm_password?.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant='subtitle1'>
                    {isVisible ? user.plain_password : '*******'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};
