import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useQuery } from '@tanstack/react-query';
import CustomPaper from '../../utilities/customPaper';
import { useForm } from 'react-hook-form';
import IssueServices from '../../../service/issue';

const status = [
  {
    name: 'OPEN',
    value: 'open',
  },
  {
    name: 'CLOSED',
    value: 'closed',
  },
  {
    name: 'RESOLVED',
    value: 'resolved',
  },
];

export const IssueFilter = ({ issueFilters }) => {
  const [cat, setCat] = React.useState(null);
  const [stat, setStat] = React.useState(null);

  const { data: categories, isLoading } = useQuery({
    queryKey: ['categories_list_issue'],
    queryFn: () => IssueServices.allCategories(),
  });

  const { handleSubmit, register } = useForm();

  const onSubmit = (data, e) => {
    issueFilters({
      category: cat?.id,
      status: stat?.name,
      from: data.from,
      to: data.to,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={2} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>catagories</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            options={!isLoading ? categories : []}
            PaperComponent={CustomPaper}
            value={cat}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setCat(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Status</Typography>
          <Autocomplete
            id='combo-box-demo'
            size='small'
            options={status}
            PaperComponent={CustomPaper}
            value={stat}
            fullWidth={true}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setStat(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>From</Typography>
          <TextField
            size='small'
            fullWidth
            {...register('from')}
            type='date'
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>To</Typography>
          <TextField
            size='small'
            fullWidth
            {...register('to')}
            type='date'
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
