import React from 'react';
import { Grid, IconButton, Button, Typography } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import PropTypes from 'prop-types';
import ShareIcon from '@mui/icons-material/Share';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useNavigate } from 'react-router-dom';
import { DialogBox } from '../../utilities/dialog';
import { ShareInspect } from './share';
import { useReactToPrint } from 'react-to-print';
import { InspectionPrint } from './inspectionPrint';

export const DrawerItem = ({ toggleDrawer, inspection }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: inspection && inspection.inspectionId,
    onAfterPrint: () => {
      // dispatch(AddedRemove());
    },
  });

  return (
    <Grid
      container
      direction='column'
      gap={4}
      sx={{ backgroundColor: '#F4F8FA', width: '300px', height: '100%' }}
    >
      <Grid item>
        <Grid
          container
          direction='column'
          sx={{
            padding: '30px 15px',
            backgroundColor: '#FFFFFF',
          }}
          gap={3}
        >
          <Grid item>
            <Grid container direction='row' justifyContent={'space-between'}>
              <Grid item>
                <IconButton onClick={toggleDrawer}>
                  <CloseTwoToneIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>Inspection</Typography>
              </Grid>
              <Grid item>
                <IconButton>
                  <MoreVertTwoToneIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='h4'>
              {inspection.inspectionId ?? '--'}
            </Typography>
          </Grid>
          <Grid item container justifyContent='space-between'>
            <Button
              onClick={() => navigate(`/inspection/${inspection.id}/report`)}
              variant='contained'
              sx={{ textTransform: 'capitalize', fontSize: 10 }}
            >
              View Report
            </Button>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              onClick={handlePrint}
              startIcon={<FileUploadOutlinedIcon />}
            >
              PDF
            </Button>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              onClick={() => setOpen(!open)}
              startIcon={<ShareIcon />}
            >
              Share
            </Button>
          </Grid>
          <DialogBox
            handleClose={() => setOpen(false)}
            open={open}
            title={
              <Typography variant='subtitle1'>
                Share a comment with vehicleNo {inspection?.vehicle_model}
              </Typography>
            }
            content={
              <ShareInspect
                toggleDrawer={toggleDrawer}
                inspection={inspection}
              />
            }
            // submit={""}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          sx={{
            padding: '30px 25px',
            backgroundColor: '#F4F8FA',
          }}
        >
          <Grid item>
            <Typography variant='h6'>Details</Typography>
          </Grid>
          <Grid
            item
            container
            direction='column'
            sx={{ backgroundColor: '#FFF', padding: '20px' }}
            gap={3}
          >
            <Grid item container direction='column'>
              <Typography variant='caption'>Status</Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.status.toLowerCase() ?? '--'}
              </Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography variant='caption'>Score</Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.scores}%
              </Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography variant='caption'>Completed</Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.total_items ?? '--'}/36
              </Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography variant='caption'>Location</Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.spot_name}
              </Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography variant='caption'>Created on </Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.createdAt}
              </Typography>
            </Grid>
            <Grid item container direction='column'>
              <Typography variant='caption'>Updated on </Typography>
              <Typography variant='body2' sx={{ fontWeight: 400 }}>
                {inspection.updatedAt}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {inspection && (
        <Grid item sx={{ display: 'none' }}>
          <InspectionPrint ref={componentRef} inspection={inspection} />
        </Grid>
      )}
    </Grid>
  );
};

DrawerItem.propTypes = {
  toggleDrawer: PropTypes.func,
  inspection: PropTypes.object,
};
