import React from 'react';
import { Grid, Typography, Divider, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Category from './categoryView';

export const ReportAudit = ({ inspection }) => {
  const [isAudit, setIsAudit] = React.useState(false);
  const questions =
    inspection.category_A !== null &&
    inspection.category_B !== null &&
    inspection.category_C !== null
      ? inspection.category_A.types.length +
        inspection.category_B.types.length +
        inspection.category_C.types.length
      : inspection.category.types.length;
  return (
    <Grid item container direction={'column'} gap={3}>
      <Grid
        item
        container
        direction='column'
        sx={{
          backgroundColor: '#2d77ab1a',
          borderRadius: '10px',
          padding: '20px 20px',
        }}
        gap={3}
      >
        <Grid item container justifyContent='space-between' alignItems='center'>
          <Grid item container xs={11} sm={12} md={11}>
            <Button
              startIcon={
                isAudit ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
              }
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={() => setIsAudit(!isAudit)}
            >
              Audit
            </Button>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={11} sm={11} md={1}>
            {inspection && (
              <Typography variant='caption' color='primary.light'>
                {inspection.total_items}/{questions} - {inspection.score}
              </Typography>
            )}
          </Grid>
        </Grid>

        {isAudit && (
          <Grid
            item
            container
            direction='column'
            gap={2}
            paddingInline={'10px'}
          >
            <Divider />
            {inspection?.category !== null ? (
              <Category
                title='Machine Checked List'
                category={inspection.category}
              />
            ) : (
              <>
                <Category title='Category A' category={inspection.category_A} />
                <Divider />
                <Category title='Category B' category={inspection.category_B} />
                <Divider />
                <Category title='Category C' category={inspection.category_C} />
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
