import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
import SpotServices from '../../../service/spot';
import AssetService from '../../../service/asset';
// import ExportReactCSV from '../../utilities/csv';
// import ExcelExport from '../../utilities/xlxs';
import CustomPaper from '../../utilities/customPaper';

export const csvHeaders = [
  {
    key: 'cat_name',
    label: 'Category',
  },
  {
    key: 'assetId_name',
    label: 'Asset ID',
  },
  {
    key: 'updatedAt',
    label: 'Last Service',
  },
  {
    key: 'last_service_smr',
    label: 'Last Service Smr',
  },
  {
    key: 'next_service_smr',
    label: 'Next Service Smr',
  },
  {
    key: 'latest_smr',
    label: 'Latest Smr',
  },
  {
    key: 'smr_left',
    label: 'Smr Left',
  },
  {
    key: 'condition',
    label: 'Condition',
  },
  {
    key: 'depart_name',
    label: 'Department',
  },
  {
    key: 'sub_name',
    label: 'Sub-Department',
  },
  {
    key: 'manuf_name',
    label: 'Manufacturer',
  },
  {
    key: 'user_name',
    label: 'User',
  },

  {
    key: 'createdAt',
    label: 'Created Date',
  },
];

export const ServiceFilter = ({ serviceFilters }) => {
  const [depart, setDepart] = React.useState(null);
  const { handleSubmit } = useForm();
  const [departId, setDepartId] = React.useState(0);
  const [cat, setCat] = React.useState(null);
  const [subDepart, setSubDepart] = React.useState(0);
  const [stat, setStat] = React.useState(null);

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_report'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { data: sites, isLoading: isSite } = useQuery({
    queryKey: ['sites_report'],
    queryFn: async () => {
      const resp = await SpotServices.allSpots();
      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { data: subDepartment, isLoading: isLoadingSubDepartment } = useQuery({
    queryKey: ['Fetch_sub_department', depart?.id],
    queryFn: depart
      ? async () => {
          const id = depart?.id;
          const resp = await DepartmentServices.subDepartmentByDepartment({
            id,
          });
          if (resp) {
            return [
              {
                createdAt: '',
                id: 0,
                name: 'Select All',
                notes: null,
                updatedAt: null,
              },
              ...resp,
            ];
          }
        }
      : [],
  });

  const { data: categories, isLoading: isCategory } = useQuery({
    queryKey: ['category_report'],
    queryFn: async () => {
      const resp = await AssetService.allAssetLabel('category');
      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    serviceFilters({
      site: stat?.id,
      department: departId,
      subDepartment: subDepart ? subDepart.id : 0,
      category: cat?.id,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={2} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Sites</Typography>
          <Autocomplete
            id='combo-box-demo'
            size='small'
            options={sites}
            value={stat}
            fullWidth={true}
            loading={isSite}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setStat(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size='small' required />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Department</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            PaperComponent={CustomPaper}
            options={!isDepartment && departments}
            value={depart}
            loading={isDepartment}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSubDepart(null);
              setDepart(newValue);
              setDepartId(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} size='small' required />
            )}
          />
        </Grid>
        {depart?.id > 0 && (
          <Grid item xs={12} sm={3}>
            <Typography variant='subtitle2'>Sub Department</Typography>
            <Autocomplete
              fullWidth={true}
              disabled={depart?.id !== 0 ? false : true}
              size='small'
              id='combo-box-demo'
              PaperComponent={CustomPaper}
              options={!isLoadingSubDepartment && subDepartment}
              value={subDepart}
              loading={isLoadingSubDepartment}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSubDepart(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size='small' required />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Category</Typography>
          <Autocomplete
            id='combo-box-demo'
            size='small'
            options={categories}
            value={cat}
            fullWidth={true}
            loading={isCategory}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setCat(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size='small' required />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
