import React from 'react';
import { Menu } from '@mui/material';
import { PropTypes } from 'prop-types';
import Fade from '@mui/material/Fade';

export const MenuButtons = ({ anchorEl, open, handleClose, items }) => {
  return (
    <Menu
      id='fade-menu'
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        sx: {
          backgroundColor: '#ffffff1a',
          backdropFilter: 'blur(29px)',
          boxShadow: '0px 0px 3px 0px #b3b3b333',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {items}
    </Menu>
  );
};

MenuButtons.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.shape),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  Fade: PropTypes.element,
  items: PropTypes.array,
};
