import React from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '../../constant/alert';
import DepartmentServices from '../../../service/department';
import CustomPaper from '../../utilities/customPaper';
import SpotServices from '../../../service/spot';
import UserServices from '../../../service/user';

export const Add = ({
  label_name,
  handleClose,
  department,
  subDepartment,
  site,
  shift,
  group,
  mutationFn,
}) => {
  const [depart, setDepart] = React.useState(null);
  const [spot, setSpot] = React.useState(null);

  const { data: departmentList, isLoading: DepartmentLoading } = useQuery({
    queryKey: ['Fetch_department'],

    queryFn: async () => {
      const data = await DepartmentServices.allDepartments();
      const defaultIndex = subDepartment
        ? data.findIndex(({ id }) => id === subDepartment?.department)
        : null;
      return {
        data: data,
        defaultIndex: defaultIndex,
      };
    },
  });
  const { data: Permissions } = useQuery({
    queryKey: ['permissions'],
    queryFn: () => UserServices.getAllPermission(),
  });

  const { data: sites, isLoading: siteLoading } = useQuery({
    queryKey: ['site_list'],
    queryFn: async () => {
      const data = await SpotServices.allSpots();

      const defaultIndex = department
        ? data?.findIndex((item) => item.id === department.site)
        : null;

      return {
        data: data,
        defaultIndex: defaultIndex,
      };
    },
  });

  const { handleSubmit, register, reset } = useForm({
    defaultValues: department || site || group || subDepartment,
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['spot_list'],
      });
      queryClient.invalidateQueries({
        queryKey: ['Fetch_department_list'],
      });
      queryClient.invalidateQueries({
        queryKey: ['sub_department_list'],
      });
      queryClient.invalidateQueries({
        queryKey: ['groups_data'],
      });
      queryClient.invalidateQueries({
        queryKey: ['shift_list'],
      });
      reset();
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { name } = data;

    const filteredObj = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => key !== 'name' && value !== false
      )
    );
    const permissions = Object.values(filteredObj);

    const departId = depart?.id;

    const siteId = spot?.id;
    if (department || site || group || subDepartment || shift) {
      mutate({
        id:
          department?.id ||
          site?.id ||
          group?.id ||
          subDepartment?.id ||
          shift?.id,
        method: 'patch',
        name,
        site: spot ? spot?.id : null,
        department: depart ? depart?.id : null,
      });
    } else {
      mutate({
        name,
        departId,
        siteId,
        permissions,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data?.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={4}>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              <Grid item container direction={'column'} gap={1} xs={12} sm={12}>
                <Typography variant='subtitle2'>{label_name}</Typography>
                <TextField
                  required={true}
                  fullWidth={true}
                  disabled={group ? true : false}
                  sx={{
                    backgroundColor: '#FFF',
                  }}
                  size='small'
                  {...register('name')}
                />
              </Grid>
            </Grid>
          </Grid>
          {label_name === 'SubDepartment' && (
            <Grid item>
              <Grid container direction={'row'} spacing={2}>
                <Grid
                  item
                  container
                  direction={'column'}
                  gap={1}
                  xs={12}
                  sm={12}
                >
                  <Typography variant='subtitle2'>Department</Typography>
                  <Autocomplete
                    id='combo-box-demo'
                    size='small'
                    loading={DepartmentLoading}
                    options={departmentList?.data}
                    value={subDepartment || depart}
                    PaperComponent={CustomPaper}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      setDepart(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {label_name === 'Department' && (
            <Grid item>
              <Grid container direction={'row'} spacing={2}>
                <Grid
                  item
                  container
                  direction={'column'}
                  gap={1}
                  xs={12}
                  sm={12}
                >
                  <Typography variant='subtitle2'>Site</Typography>
                  <Autocomplete
                    id='combo-box-demo'
                    size='small'
                    loading={siteLoading}
                    options={sites ? sites?.data : []}
                    value={spot || sites?.data[sites?.defaultIndex] || null}
                    PaperComponent={CustomPaper}
                    getOptionLabel={(option) => option?.name}
                    onChange={(event, newValue) => {
                      setSpot(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {label_name === 'Role' && (
            <Grid item container direction={'column'} gap={3}>
              <Grid item>
                <Typography>Select Permission:</Typography>
              </Grid>
              <Grid item container spacing={2}>
                {group
                  ? group?.permissions?.map((item) => (
                      <Grid item xs={6} sm={4}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked
                                value={item?.codename}
                                // {...register(`${item?.codename}`)}
                              />
                            }
                            label={
                              <Typography variant='caption'>
                                {item?.codename}
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    ))
                  : Permissions?.map((item, index) => (
                      <Grid item xs={12} sm={12} msd={12} key={index}>
                        <Grid container direction='column' gap={2}>
                          <Grid item>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 600,
                                textTransform: 'capitalize',
                              }}
                            >
                              {item?.app_label}
                            </Typography>
                          </Grid>
                          <Grid item container spacing={2}>
                            {item?.permissions?.map((sub, index) => (
                              <Grid item xs={6} sm={4}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={sub?.codename}
                                        {...register(`${sub?.codename}`)}
                                      />
                                    }
                                    label={
                                      <Typography variant='caption'>
                                        {sub?.codename}
                                      </Typography>
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          )}
          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error?.message}, Please contact the management team
              </Typography>
            )}
          </Grid>

          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            {!group && (
              <Grid item>
                <LoadingButton
                  loading={isPending}
                  sx={{
                    textTransform: 'capitalize',
                    // '&:hover': {},
                  }}
                  variant='contained'
                  autoFocus
                  type='submit'
                >
                  {department ? 'Update' : 'Submit'}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
};

Add.propTypes = {
  label_name: PropTypes.string,
  tab_name: PropTypes.string,
  handleClose: PropTypes.func,
  mutationFn: PropTypes.func,
  department: PropTypes.objectOf(PropTypes.shape),
  site: PropTypes.objectOf(PropTypes.shape),
  group: PropTypes.objectOf(PropTypes.shape),
};
