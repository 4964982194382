import React from 'react';
import { PropTypes } from 'prop-types';
import { BasicTabView } from '../../utilities/tab';
import { DepartmentComponent } from '../../components/company/department';
import { SiteComponent } from '../../components/company/site';
import { SubDepartmentComponent } from '../../components/company/subdepartment';
import { ShiftComponent } from '../../components/company/shift';

const tab = [
  {
    id: 1,
    tabTitle: 'Department',
    tabContent: <DepartmentComponent />,
  },
  {
    id: 2,
    tabTitle: 'Sub Department',
    tabContent: <SubDepartmentComponent />,
  },
  {
    id: 3,
    tabTitle: 'Site',
    tabContent: <SiteComponent />,
  },

  {
    id: 4,
    tabTitle: 'Shift',
    tabContent: <ShiftComponent />,
  },
];
export const Company = () => {
  return <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />;
};

Company.propTypes = {
  tab: PropTypes.array,
};
