import { Grid } from '@mui/material';
import React from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { DialogBox } from '../../../utilities/dialog';
import { ShareInspect } from '../../prestart/share';
import { AddIssue } from '../../issue/addIssue';
import AssetService from '../../../../service/asset';
import { useQuery } from '@tanstack/react-query';
import { AssetOverview } from './overview';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

export const AssetDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isDialog, setIsDialog] = React.useState(false);

  const [setMobileOpens, setMobileOpenssetMobileOpens] = React.useState(false);
  const toggleDrawer = () => {
    setMobileOpenssetMobileOpens(!setMobileOpens);
  };

  const {
    data: asset,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['asset', id],
    queryFn: id
      ? async () => {
          const method = 'get';
          const result = await AssetService.asset({ id, method });
          return result;
        }
      : {},
  });

  return (
    <Grid
      container
      direction='column'
      sx={{
        padding: { sm: '20px 60px' },
      }}
      gap={3}
    >
      <Grid item container direction='row' spacing={2}>
        <Grid item xs={12} sm={8}>
          <Grid container direction='row' gap={1} alignItems='center'>
            <Button
              onClick={() => navigate('/assets')}
              startIcon={<KeyboardBackspaceOutlinedIcon />}
              sx={{ textTransform: 'capitalize' }}
            >
              <Typography variant='body2'>Back</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item container justifyContent='flex-end' xs={12} sm={4} gap={1}>
          <Grid item>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              startIcon={<LayersOutlinedIcon />}
              onClick={() => setIsDialog(true)}
            >
              Actions
            </Button>
          </Grid>

          <Grid item>
            <Button
              sx={{ fontSize: 10 }}
              variant='outlined'
              startIcon={<ShareIcon />}
              onClick={() => setOpen(!open)}
            >
              Share
            </Button>
            <DialogBox
              handleClose={() => setOpen(false)}
              open={open}
              title={
                <Typography variant='subtitle1'>
                  Share a comment with vehicleNo
                  {asset && asset.assetId}
                </Typography>
              }
              content={
                <ShareInspect toggleDrawer={toggleDrawer} inspection={{}} />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        sx={{ padding: '0px 0px 10px 0px' }}
        spacing={2}
      >
        <Grid item xs={12} sm={9}>
          <Grid container direction='column' gap={3}>
            <Grid
              item
              container
              direction='column'
              sx={{
                backgroundColor: '#2d77ab1a',
                borderRadius: '10px',
                padding: '20px 20px',
              }}
              gap={3}
            >
              <Grid item container justifyContent='space-between'>
                <Typography variant='h6'>Overview</Typography>
                <Button
                  variant='text'
                  sx={{
                    color: 'green',
                  }}
                  startIcon={<DirectionsCarIcon />}
                >
                  {asset && asset.statusName}
                </Button>
              </Grid>
              {asset && (
                <Grid item>
                  <AssetOverview asset={asset} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            direction='column'
            sx={{
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
              padding: '20px 20px',
            }}
          >
            <Grid item>
              <Typography variant='body1' color='primary.light'>
                Overview
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Report Issue -<small></small>
          </Typography>
        }
        content={
          <AddIssue handleClose={() => setIsDialog(false)} inspection={[]} />
        }
      />
    </Grid>
  );
};
