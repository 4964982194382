import axiosInstance from './axios';

const login = (data) => {
  // console.log(data);
  return axiosInstance
    .post('user/login/', { idnumber: data.idnumber, password: data.password })
    .then((res) => {
      const data = (axiosInstance.defaults.headers['Authorization'] =
        'Bearer ' + res.data.access);

      // console.log(res.data);
      sessionStorage.setItem('user', JSON.stringify(res.data));
      return data;
    });
};

const logout = (data) => {
  // console.log(data);
  return axiosInstance
    .post('user/logout/', { refresh: data.refreshLogout })
    .then((res) => {
      return res.data;
    });
};

const AuthService = {
  login,
  logout,
};

export default AuthService;
