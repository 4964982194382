import React, { useEffect } from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  // Drawer,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import { MenuButtons } from '../../utilities/menuButton';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { DialogBox } from '../../utilities/dialog';
import { AddIssue } from './addIssue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import IssueServices from '../../../service/issue';
import { DeleteContent } from '../../../hooks/utils';
import { DetailItem } from './detail';
import { IssueFilter } from './precheckfilter';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation } from 'react-router-dom';

const items = [
  {
    label: 'Issues',
    icon: <DescriptionOutlinedIcon fontSize='small' />,
  },
  {
    label: 'Edit Issue',
    icon: <EditIcon fontSize='small' />,
  },
  // {
  //   label: 'Delete Issue',
  //   icon: <DeleteIcon fontSize='small' />,
  // },
];

const csvCol = [
  {
    key: 'name',
    label: 'Title',
  },

  {
    key: 'user_fullname',
    label: 'Conducted by ',
  },
  {
    key: 'inspection_id',
    label: 'InspectionId',
  },
  {
    key: 'category_name',
    label: 'Category',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'createdAt',
    label: 'CreatedAt',
  },
];

export const PrestartIssue = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialog, setIsDialog] = React.useState(false);
  const [isDialogDetail, setIsDialogDetail] = React.useState(false);
  const [setMobileOpens, setMobileOpenss] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [filterIssuesData, setFilterIssuesData] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [issue, setIssue] = React.useState(null);

  const { state } = useLocation();

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['issues'],
    queryFn: () => IssueServices.allIssues(),
  });

  const { mutate } = useMutation({
    mutationFn: IssueServices.issue,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['issues'] });
      setIsDelete(false);
    },
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setMobileOpenss(!setMobileOpens);
  };

  const columns = [
    {
      field: 'inspection_id',
      headerName: 'InspectionId',
      width: 150,
    },
    {
      field: 'name',
      headerName: 'Title',
      width: 250,
    },

    {
      field: 'user_fullname',
      headerName: 'Conducted by ',
      width: 180,
    },

    {
      field: 'category_name',
      headerName: 'Category',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      width: 240,
    },

    {
      field: '',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];

  const handleMenuItem = (item, params) => {
    if (item.label === 'Issues') {
      setIsDialogDetail(true);
    } else if (item.label === 'Edit Issue') {
      setAnchorEl(null);
      setIsDialog(() => true);
    } else if (item.label === 'Delete Issue') {
      setAnchorEl(null);
      setIsDelete(true);
    } else {
      return null;
    }
  };

  const { mutate: issueFilters, isPending: issueFilterPending } = useMutation({
    mutationFn: IssueServices.allIssueFiltered,
    onSuccess: (data) => {
      setFilterIssuesData(data);
    },
  });

  useEffect(() => {
    if (state !== null) {
      setIssue(state?.item);
      setIsDialogDetail(true);
    }
  }, [state]);

  return (
    <>
      <MainContent
        csvData={data}
        csvHeaders={csvCol}
        csvDataWithFilter={filterIssuesData || search}
        csvHeadersWithFilter={csvCol}
        fileNameWithFilter={'Issue list'}
        fileName={'Issue list'}
        isFilter={true}
        clearData={() => setFilterIssuesData(null)}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setSearch(() => null);
            return;
          }
          const value = data.filter(
            (item) => item.name.toLowerCase().indexOf(val.target.value) > -1
          );
          setSearch(() => value);
        }}
        filterContent={<IssueFilter issueFilters={issueFilters} />}
        items={
          <TableData
            cols={columns}
            rows={filterIssuesData || search || data}
            maxWidthMob='84vw'
            height='530px'
            isLoading={issueFilterPending || isLoading}
            onRowClick={({ row }) => {
              setIssue(() => row);
            }}
            onCellClick={({ field }) => {
              if (field !== '') {
                setIsDialogDetail(true);
              }
              // // navigate(`/inspection/${row.id}/report`);
              // console.log(params);
            }}
          />
        }
        fil
        isBtnName={false}
        btnName={'Add'}
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Report Issue
          </Typography>
        }
        content={
          <AddIssue handleClose={() => setIsDialog(false)} issue={issue} />
        }
      />
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete Issue
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this issue ?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = issue.id;
              const method = 'delete';
              mutate({ id, method });
            }}
          />
        }
      />
      <DialogBox
        handleClose={() => setIsDialogDetail(false)}
        open={isDialogDetail}
        title={
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography variant='subtitle1' component='span'>
                Issue -{issue?.inspection_id}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setIsDialogDetail(!isDialogDetail);
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
        content={<DetailItem toggleDrawer={toggleDrawer} issue={issue} />}
      />
    </>
  );
};
