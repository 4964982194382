import React from 'react';
import { PropTypes } from 'prop-types';
import { BasicTabView } from '../../utilities/tab';
import { PrestartIssue } from '../../components/issue/prechecklist';
import { CategoryComponent } from '../../components/issue/category';
// import { AssetWithNoPrestart } from '../../components/issue/assetList';

const tab = [
  {
    id: 1,
    tabTitle: 'Prestart',
    tabContent: <PrestartIssue />,
  },
  // {
  //   id: 2,
  //   tabTitle: 'Asset with No Prestart',
  //   tabContent: <AssetWithNoPrestart />,
  // },
  {
    id: 3,
    tabTitle: 'Categories',
    tabContent: <CategoryComponent />,
  },
];
export const Issue = () => {
  return <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />;
};

Issue.propTypes = {
  tab: PropTypes.array,
};
