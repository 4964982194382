import React, { useEffect } from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InspectionServices from '../../../service/inspection';

export const SMR = ({ onCancel, inspection }) => {
  const { handleSubmit, register } = useForm({});
  const [error, setError] = React.useState();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: InspectionServices.UpdatePrestartSMR,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['report_inspection'],
      });
      queryClient.invalidateQueries({ queryKey: ['inspections'] });
      queryClient.invalidateQueries({ queryKey: ['Fetch_assets'] });
    },
    onError: (err) => {
      setError(err);
    },
  });
  const onSubmit = (data, e) => {
    e.preventDefault();
    const { id } = inspection;
    const { mileage } = data;
    mutate({
      id,
      mileage,
    });
    onCancel();
  };

  useEffect(() => {}, [inspection]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' gap={5}>
        <Grid item container direction='column' gap={2}>
          <Grid item>
            <Typography variant='body1'>
              Current SMR is &nbsp;
              <Typography variant='body1' component='span' fontWeight='bold'>
                {inspection?.mileage}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type='number'
              placeholder='enter a SMR'
              {...register('mileage')}
              required
            />
          </Grid>
        </Grid>
        {isError && (
          <Grid item container justifyContent='space-between'>
            <Typography variant='caption' color='red'>
              {error?.message}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item>
              <Button onClick={onCancel}>Cancel</Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='contained'
                // autoFocus
                type='submit'
              >
                Edit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
