import { Grid, Paper, Typography, ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';

export const Questions = ({ label, value }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '20px 20px',
        borderRadius: '10px',
      }}
    >
      <Grid container direction='column' gap={2}>
        <Grid item>
          <Typography color={value === null && '#00000042'}>{label}</Typography>
        </Grid>
        <Grid item container justifyContent='space-between'>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'yes' ? '#0092844f' : null,
              color: value === 'yes' ? '#00574e' : null,
            }}
            variant='answerBtn'
            disabled={!(value === 'yes')}
          >
            Yes
          </ButtonBase>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'no' ? '#bd00004f' : null,
              color: value === 'no' ? '#bd0000' : null,
            }}
            variant='answerBtn'
            disabled={!(value === 'no')}
          >
            No
          </ButtonBase>
          <ButtonBase
            sx={{
              minWidth: '180px',
              backgroundColor: value === 'n/a' ? '#bcbd004f' : null,
              color: value === 'n/a' ? '#6b6b00' : null,
            }}
            variant='answerBtn'
            disabled={!(value === 'n/a')}
          >
            N/A
          </ButtonBase>
        </Grid>
      </Grid>
    </Paper>
  );
};

Questions.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
