import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import IssueServices from '../../../service/issue';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import Alert from '../../constant/alert';
import CustomPaper from '../../utilities/customPaper';

const inputField = [
  {
    label_name: 'Title',
    form: 'name',
    type: 'text',
    required: true,
  },
  {
    label_name: 'Description',
    form: 'description',
    type: 'text',
    required: true,
    rows: 3,
    multiline: true,
  },
  {
    label_name: 'What needs to be done?',
    form: 'needs',
    type: 'text',
    required: true,
    rows: 3,
    multiline: true,
  },
];

export const AddIssue = ({ handleClose, issue, inspection }) => {
  const { handleSubmit, register, reset } = useForm({ defaultValues: issue });
  const [category, setCategory] = React.useState(null);
  const [user, setUser] = React.useState('');
  const queryClient = useQueryClient();

  const { data: categories } = useQuery({
    queryKey: ['categories_list_issue'],
    queryFn: async () => {
      const fetchedCat = await IssueServices.allCategories();
      const defaultIndex = issue
        ? fetchedCat.findIndex(({ id }) => id === issue.category)
        : null;
      return {
        data: fetchedCat,
        defaultIndex: defaultIndex,
      };
    },
  });

  // mutation
  const { mutate, isPending, data, isSuccess, error, isError } = useMutation({
    mutationFn: issue ? IssueServices.issue : IssueServices.addIssue,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['issues'] });
      reset();
      setCategory(null);
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { name, description, needs } = data;
    if (issue) {
      mutate({
        id: issue.id,
        method: 'patch',
        name,
        description,
        needs,
        category: category?.id || issue.category,
      });
    } else {
      const { id } = inspection;
      mutate({ name, description, needs, category: category.id, user, id });
    }
  };

  const getUser = () => {
    const { authenticated } = JSON.parse(sessionStorage?.getItem('user'));
    setUser(authenticated.id);
  };
  useEffect(() => {
    getUser();
  }, []);

  console.log(categories);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        <Alert text={data?.message} handleClose={handleClose} />
      ) : (
        <Grid container direction={'column'} gap={4}>
          <Grid item>
            <Grid container direction={'row'} spacing={2}>
              {inputField.map((value, i) => (
                <Grid
                  item
                  container
                  direction={'column'}
                  gap={1}
                  key={i}
                  xs={12}
                  sm={12}
                >
                  <Typography variant='subtitle2'>
                    {value?.label_name}
                  </Typography>
                  <TextField
                    {...value}
                    sx={{
                      backgroundColor: '#FFF',
                    }}
                    size='small'
                    {...register(`${value.form}`)}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={12}>
                <Typography>what type of issue?</Typography>
                <Autocomplete
                  id='combo-box-demo'
                  options={categories !== undefined ? categories?.data : []}
                  value={
                    issue &&
                    (category ||
                      categories?.data[categories?.defaultIndex] ||
                      null)
                  }
                  fullWidth={true}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, newValue) => {
                    setCategory(() => newValue);
                  }}
                  PaperComponent={CustomPaper}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item container spacing={2}>
            <Button
              startIcon={<AddIcon />}
              sx={{
                textTransform: 'capitalize',
              }}
              variant='text'
            >
              Add note
            </Button>
            <Button
              startIcon={<LocationOnIcon />}
              sx={{
                textTransform: 'capitalize',
              }}
              variant='text'
            >
              Location
            </Button>
          </Grid> */}
          <Grid item>
            {isError && (
              <Typography variant='caption' color='red'>
                {error?.message}, Please contact the management team
              </Typography>
            )}
          </Grid>
          <Grid item container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  textTransform: 'capitalize',
                }}
                variant='text'
                autoFocus
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isPending}
                sx={{
                  textTransform: 'capitalize',
                  backgroundColor: '#2D77AB',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#2D77AB',
                  },
                }}
                variant='contained'
                type='submit'
              >
                {issue ? 'Update' : 'Submit'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
AddIssue.propTypes = {
  handleClose: PropTypes.func,
  issue: PropTypes.objectOf(PropTypes.shape),
};
