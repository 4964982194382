import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { MenuButtons } from '../../utilities/menuButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogBox } from '../../utilities/dialog';
import { DeleteContent } from '../../../hooks/utils';
import { Add } from './add';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import AssetService from '../../../service/asset';

const items = [
  {
    label: 'Edit model',
    icon: <EditIcon fontSize='small' />,
  },
  {
    label: 'Delete model',
    icon: <DeleteIcon fontSize='small' />,
  },
];

const csvCol = [
  {
    key: 'name',
    label: 'Name',
  },

  {
    key: 'createdAt',
    label: 'CreatedAt',
  },
  {
    key: 'updatedAt',
    label: 'UpdatedAt',
  },
];

export const AssetModelComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialog, setIsDialog] = React.useState(false);
  const [setMobileOpens, setMobileOpenss] = React.useState(false);
  const [model, setModel] = React.useState(null);
  const [filtered, setFiltered] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['assetModel'],
    queryFn: () => AssetService.allAssetLabel('model'),
  });

  const { mutate } = useMutation({
    mutationFn: AssetService.AssetLabel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assetModel'] });
      setIsDelete(false);
    },
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setMobileOpenss(!setMobileOpens);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 580,
    },

    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      width: 250,
    },
    {
      field: 'updatedAt',
      headerName: 'UpdatedAt',
      width: 240,
    },

    {
      field: '',
      headerName: '',
      width: 20,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];

  const handleMenuItem = (item, params) => {
    if (item.label === 'model') {
      toggleDrawer();
      setAnchorEl(null);
    } else if (item.label === 'Edit model') {
      // navigate('/inspection/edit');
      setAnchorEl(null);
      setIsDialog(() => true);
    } else if (item.label === 'Delete model') {
      setAnchorEl(null);
      setIsDelete(() => true);
    } else {
      return null;
    }
  };
  return (
    <>
      <MainContent
        csvData={data}
        csvHeaders={csvCol}
        csvDataWithFilter={filtered || data}
        csvHeadersWithFilter={csvCol}
        fileNameWithFilter={'Assert Model list'}
        fileName={'Assert Model list'}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setFiltered(() => data);
            return;
          }
          const value = data.filter(
            (item) => item.name.toLowerCase().indexOf(val.target.value) > -1
          );
          setFiltered(() => value);
        }}
        title='Model Assets'
        isBtnName={true}
        valueFrom='add_assetmodel'
        btnName={'Add'}
        onBtnNameClick={() => {
          setModel(null);
          setIsDialog(true);
        }}
        items={
          <TableData
            cols={columns}
            rows={filtered ? filtered : data}
            isLoading={isLoading}
            height='400px'
            onRowClick={({ row }) => setModel(() => row)}
          />
        }
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        title={
          <Typography variant='subtitle1' component='span'>
            Asset Model
          </Typography>
        }
        content={
          <Add
            label_name='Model'
            handleClose={() => setIsDialog(false)}
            model={model}
            mutationFn={
              model ? AssetService.AssetLabel : AssetService.addAssetLabel
            }
          />
        }
      />
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete Asset Model
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this asset Model ?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = model.id;
              const method = 'delete';
              const label = 'model';
              mutate({ id, method, label });
            }}
          />
        }
      />
    </>
  );
};
