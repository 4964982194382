/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withRouter } from '../routes/withRouter';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  Avatar,
  Typography,
  ButtonBase,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DrawDrawerViewer from './drawer';
import axiosInstance from '../../service/axios';
import { MenuButtons } from '../utilities/menuButton';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../../service/auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import CircularProgress from '@mui/material/CircularProgress';
import InactivityHandler from '../utilities/inactivityHandler';

const drawerWidth = 250;

const Layout = (props) => {
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(() => !mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [refreshLogout, setRefreshLogout] = React.useState('');
  const [user, setUser] = React.useState({
    id: null,
    fullname: null,
  });
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItem = (item) => {
    if (item.label === 'Profile') {
      navigate(`/user/detail/${user.id}`);
      setAnchorEl(null);
    } else if (item.label === 'Log out') {
      mutate({ refreshLogout });
    } else {
      return null;
    }
  };
  const { mutate, isPending } = useMutation({
    mutationFn: AuthService.logout,
    onSuccess: () => {
      sessionStorage.removeItem('user');
      sessionStorage.clear();
      setAnchorEl(null);
      navigate('/login');
    },
  });

  const items = [
    {
      label: 'Profile',
      icon: <PersonIcon fontSize='10px' color='#032842' />,
    },
    {
      label: 'Log out',
      icon: isPending ? (
        <CircularProgress size={20} />
      ) : (
        <LogoutIcon fontSize='10px' color='#032842' />
      ),
    },
  ];

  const getUser = () => {
    const { refresh, authenticated } =
      JSON.parse(sessionStorage.getItem('user')) ?? {};
    setRefreshLogout(refresh);

    setUser({
      id: authenticated.id,
      displayName: authenticated.displayName,
    });
  };

  useEffect(() => {
    getUser();
  }, [axiosInstance]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <InactivityHandler timeout={180000} />
      <AppBar
        position='fixed'
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'transparent',
          margin: {
            xs: '0px 0px',
            md: '0px 40px',
          },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuOutlinedIcon style={{ color: '#000' }} />
          </IconButton>
          <Grid sx={{ flexGrow: 1 }} />
          <Link
            to={
              process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_LOCAL_LINK_WEBSITE
                : process.env.REACT_APP_REMOTE_LINK_WEBSITE
            }
            target='_blank'
          >
            <IconButton>
              <LanguageIcon
                sx={{
                  color: '#FFA500',
                  width: { sm: 25, xs: 25 },
                  height: { sm: 25, xs: 25 },
                }}
              />
            </IconButton>
          </Link>

          <Grid sx={{ paddingInline: '10px' }} />
          <ButtonBase
            component='button'
            onClick={handleClick}
            disableRipple
            // justifyContent="center"
          >
            <Avatar sx={{ backgroundColor: 'transparent' }}>
              <AccountCircleIcon
                sx={{
                  color: 'primary.light',
                  width: { sm: 25, xs: 25 },
                  height: { sm: 25, xs: 25 },
                }}
              />
            </Avatar>
          </ButtonBase>
          <MenuButtons
            sx={{ paddingInline: '10px' }}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            items={items.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItem(item)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>
                  <Typography variant='subtitle2'>{item.label}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
          />
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          position: 'relative',
        }}
        aria-label='mailbox folders'
      >
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          elevation={1}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              width: { xs: '80%' },
              boxSizing: 'border-box',
              backgroundColor: 'white',
            },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {<DrawDrawerViewer />}
        </Drawer>
        <Grid
          container
          sx={{
            display: { xs: 'none', sm: 'flex' },
            // backgroundColor: '#FFF',

            position: 'relative',
            zIndex: '1200',
            // overflowY: ',
          }}
          alignItems='center'
          height='100%'
          // minHeight={'100%'}
          width={drawerWidth}
        >
          {<DrawDrawerViewer />}
        </Grid>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: {
            xs: `calc(100% - ${drawerWidth}px)`,
          },
          minHeight: '100vh',
          padding: {
            xs: '0px 20px',
            sm: '0px 30px',
            md: '0px 70px',
          },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          zIndex: '-1',
          height: '100vh',
          width: '100%',
          top: 0,
          backgroundSize: 'cover',
          backgroundImage:
            'url(https://res.cloudinary.com/doti9oyov/image/upload/v1713001687/desktop-02_bgeagk.jpg)',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </Box>
  );
};
Layout.propTypes = {
  children: PropTypes.element,
  window: PropTypes.func,
};

export default withRouter(Layout);
