/* eslint-disable no-undef */
import React from 'react';
import { MainContent } from '../../utilities/mainContent';
import TableData from '../../utilities/table';
import {
  Grid,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Avatar,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuButtons } from '../../utilities/menuButton';
import { useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import EditIcon from '@mui/icons-material/Edit';
import { DialogBox } from '../../utilities/dialog';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import UserServices from '../../../service/user';
import { AddUser } from './addUser';
import { DeleteContent } from '../../../hooks/utils';
import { UserFilter } from './userFilter';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';

const items = [
  {
    label: 'User',
    icon: <DescriptionOutlinedIcon fontSize='small' />,
  },

  {
    label: 'Delete User',
    icon: <DeleteIcon fontSize='small' />,
  },
];

const csvCols = [
  {
    key: 'idnumber',
    headerName: 'UserId',
  },
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'display_name',
    label: 'Display Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'cellphone',
  },
  {
    key: 'department_name',
    label: 'Department',
  },

  {
    key: 'createdAt',
    label: 'Created Date',
  },
];

export const UserComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDialog, setIsDialog] = React.useState(false);
  const [setMobileOpens, setMobileOpenss] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [filterUserData, setFilterUserData] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => UserServices.getAllUser(),
  });

  const { mutate } = useMutation({
    mutationFn: UserServices.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      setIsDelete(false);
    },
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setMobileOpenss(!setMobileOpens);
  };

  const columns = [
    {
      field: 'idnumber',
      headerName: 'UserId',
      width: 90,
      renderCell: (params) => {
        return (
          <Typography
            sx={{ color: 'red', fontWeight: 'semi-bold', fontSize: 14 }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'thumbnail',
      headerName: 'Photo',
      width: 150,
      renderCell: (params) => {
        return <Avatar sizes='small' />;
      },
    },
    {
      field: 'first_name',
      headerName: 'Full Name',
      width: 180,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                component={Link}
                to={`/user/detail/${params.id}`}
                sx={{
                  fontWeight: 'semi-bold',
                  textTransform: 'capitalize',
                  fontSize: 14,
                  textDecoration: 'none',
                }}
              >
                {params.value}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },

    {
      field: 'display_name',
      headerName: 'Display Name',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontWeight: 'semi-bold',
              textTransform: 'capitalize',
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item>
              <EmailIcon />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                {params.value}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'phone',
      headerName: 'cellphone',
      width: 120,
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            <Grid item>
              <LocalPhoneIcon />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                {params.value}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography
            component={Link}
            to={'/company'}
            sx={{
              fontWeight: 'semi-bold',
              textTransform: 'capitalize',
              fontSize: 14,
              textDecoration: 'none',
            }}
          >
            {`${params.value?.length} ${params.value?.length > 1 ? `Departments` : `Department`}`}
          </Typography>
        );
      },
    },

    {
      field: 'createdAt',
      headerName: 'Created Date',
      width: 240,
    },

    {
      field: '',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        return (
          <Grid container justifyContent='flex-end'>
            <IconButton
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <MenuButtons
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              items={items.map((item, index) => (
                <MenuItem
                  key={item.label}
                  onClick={() => handleMenuItem(item, params)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle2'>{item.label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
            />
          </Grid>
        );
      },
    },
  ];

  const handleMenuItem = (item) => {
    if (item.label === 'User') {
      toggleDrawer();
      navigate(`/user/detail/${user.id}`);
      setAnchorEl(null);
    } else if (item.label === 'Edit User') {
      setAnchorEl(null);
      setIsDialog(() => true);
    } else if (item.label === 'Delete User') {
      setAnchorEl(null);
      setIsDelete(true);
    } else {
      return null;
    }
  };
  const { mutate: userFilters, isPending: userFilterPending } = useMutation({
    mutationFn: UserServices.getAllUserFilter,
    onSuccess: (data) => {
      setFilterUserData(data);
    },
  });

  return (
    <>
      <MainContent
        csvData={users}
        csvHeaders={csvCols}
        csvDataWithFilter={filterUserData || search}
        csvHeadersWithFilter={csvCols}
        fileNameWithFilter={'User list'}
        fileName={'User list'}
        isFilter={true}
        clearData={() => setFilterUserData(null)}
        onChangeSearch={(val) => {
          if (val.target.value === '') {
            setSearch(() => users);
            return;
          }
          const value = users.filter(
            (item) =>
              item.idnumber?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.display_name?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.email?.toLowerCase().indexOf(val.target.value) > -1 ||
              item.first_name?.toLowerCase().indexOf(val.target.value) > -1
          );

          setSearch(value);
        }}
        // onclickFilter={() => {}}

        title='Users'
        valueFrom={'add_user'}
        filterContent={<UserFilter userFilters={userFilters} />}
        items={
          <TableData
            cols={columns}
            rows={filterUserData || search || users}
            maxWidthMob='84vw'
            height='500px'
            isLoading={isLoading || userFilterPending}
          />
        }
        isBtnName={true}
        btnName={'Add'}
        onBtnNameClick={() => {
          setUser(null);
          setIsDialog(true);
        }}
      />
      <DialogBox
        handleClose={() => setIsDialog(false)}
        open={isDialog}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            User
          </Typography>
        }
        content={<AddUser handleClose={() => setIsDialog(false)} user={user} />}
      />
      <DialogBox
        handleClose={() => setIsDelete(false)}
        open={isDelete}
        maxWidth='500px'
        title={
          <Typography variant='subtitle1' component='span'>
            Delete user
          </Typography>
        }
        content={
          <DeleteContent
            title='Are you sure you want to delete this user?'
            onCancel={() => setIsDelete(false)}
            onSubmit={() => {
              const id = user.id;
              mutate({ id });
            }}
          />
        }
      />
    </>
  );
};
