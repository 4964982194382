import React from 'react';
import { CheckList } from './checklist';
import { machineItems } from '../../../utilities/question';

export const MachineCheckList = () => {
  const asset = {
    'Check List': [],
    'Extra details': [],
  };
  return <CheckList form='machine' itemsMachine={machineItems} asset={asset} />;
};
