import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { RecentView } from '../../components/home/recentView';
import { IncomingView } from '../../components/home/incoming';
import { HomeDetails } from '../../components/home';

const Home = () => {
  const [user, setUser] = React.useState({
    id: null,
    fullname: null,
    idnumber: null,
    departName: null,
  });
  const getUser = () => {
    const { authenticated } = JSON.parse(sessionStorage.getItem('user')) ?? {};

    setUser({
      id: authenticated.id,
      displayName: authenticated.displayName,
      idnumber: authenticated.idnumber,
      departName: authenticated.departName,
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <Grid container direction='row' gap={6}>
      <Grid item xs={12} sm={12} container direction='column' gap={2}>
        <HomeDetails user={user} />
      </Grid>
      <Grid item xs={12} sm={12} container direction='column' gap={2}>
        <RecentView />
      </Grid>
      <Grid item xs={12} sm={12} container direction='column' gap={2}>
        <Typography variant='subtitle1' color='secondary.main'>
          Incoming prestart
        </Typography>
        <IncomingView />
      </Grid>
    </Grid>
  );
};

export default Home;
