import React from 'react';
import { PrestartView } from '../../components/prestart';
import { PropTypes } from 'prop-types';
import { BasicTabView } from '../../utilities/tab';
import { ReportView } from '../../components/prestart/compliance';

const tab = [
  {
    id: 1,
    tabTitle: 'Check Lists',
    tabContent: <PrestartView />,
  },
  {
    id: 2,
    tabTitle: 'Compliance Report',
    tabContent: <ReportView />,
  },
];

export const Prestart = () => {
  return <BasicTabView tabPanel={tab} width={'100%'} height={'300px'} />;
};

Prestart.propTypes = {
  tab: PropTypes.array,
};
