import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useQuery } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
import { useForm } from 'react-hook-form';
import CustomPaper from '../../utilities/customPaper';
import SpotServices from '../../../service/spot';

export const InspectionFilter = ({ inspectionFilters }) => {
  const [depart, setDepart] = React.useState(null);
  const [site, setIsDelete] = React.useState(null);

  const { handleSubmit, register } = useForm();

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['Fetch_department'],
    queryFn: () => DepartmentServices.allDepartments(),
  });

  const { data: sites, isLoading: isSite } = useQuery({
    queryKey: ['site_filter'],
    queryFn: async () => {
      const resp = await SpotServices.allSpots();
      return [
        {
          id: 0,
          name: 'All',
          notes: '',
          updatedAt: '',
          createdAt: '',
        },
        ...resp,
      ];
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    inspectionFilters({
      department: depart.id,
      site: site.id,
      from: data.from,
      to: data.to,
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='row' spacing={2} alignContent='center'>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Department</Typography>
          <Autocomplete
            fullWidth={true}
            size='small'
            id='combo-box-demo'
            loading={isDepartment}
            options={!isDepartment ? departments : []}
            value={depart}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setDepart(newValue);
            }}
            renderInput={(params) => <TextField {...params} size='small' />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>Site</Typography>
          <Autocomplete
            id='combo-box-demo'
            size='small'
            options={sites}
            value={site}
            fullWidth={true}
            loading={isSite}
            getOptionLabel={(option) => option.name}
            PaperComponent={CustomPaper}
            onChange={(event, newValue) => {
              setIsDelete(newValue);
            }}
            renderInput={(params) => <TextField {...params} size='small' />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>From</Typography>
          <TextField size='small' fullWidth {...register('from')} type='date' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant='subtitle2'>To</Typography>
          <TextField
            size='small'
            fullWidth
            {...register('to')}
            type='date'
            // inputProps={{ style: { backgroundColor: 'red' } }}
          />
        </Grid>
        <Grid item xs={12} sm={4} container>
          <Button
            sx={{
              textTransform: 'capitalize',
              height: '35px',
              alignSelf: 'end',
              backgroundColor: 'primary.light',
              color: 'white',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            variant='filled'
            type='submit'
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
